import React from 'react';
import {
  Box,
  Button,
  Card,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import { useReservationContext } from '@src/providers/ReservationProvider';
import CustomIcon from '@src/components/ui/CustomIcon';
import { CameraAlt } from '@mui/icons-material';
import CameraSelfie from './CameraSelfie';

const CheckinCode: React.FC = () => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.CheckinCode' });
  const { guestBook } = useGuestBookContext();
  const { reservation } = useReservationContext();

  if (guestBook?.checkinStatus === 'selfie-registration' || guestBook?.checkinStatus === 'submitted'){
    return (
      <Card sx={{
        background: (theme) => theme.palette.secondary.gradient,
        color: 'secondary.contrastText'
      }}>
        <Stack
          spacing={2}
          sx={{
            alignItems: 'center',
            p: 3,
          }}
        >
          {guestBook.checkinStatus === 'submitted' &&
            <>
              <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
                width='100%'
              >
                <Typography variant="label4Bold">{t('label')}:</Typography>
                <Typography variant="label2Bold">{guestBook?.checkinCode}</Typography>
              </Stack>

              <Stack spacing={1} sx={{ alignItems: 'center' }}>
                <Box
                  component="img"
                  src={guestBook?.checkinCodeSVG}
                  borderRadius="8px"
                  bgcolor="#fff"
                  my={2}
                  mx="auto"
                  display="block"
                  width="144px"
                  height="144px"
                />
                <Typography variant='label3Bold'>
                  {t('ciQrCode')}
                </Typography>
              </Stack>

              <Typography variant="body2">{t('description')}</Typography>

              {guestBook.japaneseNationalOrResident &&
                <Stack direction='row' sx={{ justifyContent: 'space-between', pt: 3, borderTop: '1px solid #fff', width: '100%', alignItems: 'center' }}>
                  <Stack spacing={0.5}>
                    <CustomIcon variant='faceMatch' color='white' sx={{ height: 40, width: 40 }}/>
                    <Typography variant='body1' color='white'>{t('photo.submitted')}</Typography>
                  </Stack>
                  <CameraSelfie />
                </Stack>
              }
            </>
          }

          {guestBook?.checkinStatus === 'selfie-registration' &&
            <Stack spacing={3}>
              <CustomIcon variant='faceMatch' color='white' sx={{ height: 64 }}/>
              <Stack spacing={1}>
                <Typography variant='body1' fontWeight={400}>{t('photo.instruction_1')}</Typography>
                <Typography variant='body1' fontWeight={400}>{t('photo.instruction_2')}</Typography>
              </Stack>
              <CameraSelfie />
            </Stack>
          }
        </Stack>
      </Card>
    );
  } else {
    return null;
  }
};

export default CheckinCode;

