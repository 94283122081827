import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

/**
 * Detect whether the device accepts touch.
 */
export const isTouchDevice = () => (
  ('ontouchstart' in window) ||
  (navigator.maxTouchPoints > 0) ||
  (navigator.msMaxTouchPoints > 0)
);

/**
 *
 */
export const useBreakpoints = () => {
  const theme = useTheme();

  return {
    get xlUp() {
      return useMediaQuery(theme.breakpoints.up('xl'));
    },
    get xlDown() {
      return useMediaQuery(theme.breakpoints.down('xl'));
    },
    get lgUp() {
      return useMediaQuery(theme.breakpoints.up('lg'));
    },
    get lgDown() {
      return useMediaQuery(theme.breakpoints.down('lg'));
    },
    get mdUp() {
      return useMediaQuery(theme.breakpoints.up('md'));
    },
    get mdDown() {
      return useMediaQuery(theme.breakpoints.down('md'));
    },
    get smUp() {
      return useMediaQuery(theme.breakpoints.up('sm'));
    },
    get smDown() {
      return useMediaQuery(theme.breakpoints.down('sm'));
    },
  };
};


/**
 * Calculate dimensions of headers
 */
export const useAppBarHeight = () => {
  const theme = useTheme();

  const appBarBreakpoints = Object.entries(theme.mixins.toolbar).map(([key, value]) => useMediaQuery(key) && value).reverse();

  return appBarBreakpoints.find(i => i)?.minHeight || theme.mixins.toolbar.minHeight;
};
