import {
  Card,
  Container,
  Typography,
} from '@mui/material';
import Header from '@src/routes/LockScreen/Header';
import Footer from '@src/components/ui/Footer';
import LanguageSwitcher from '@src/components/ui/LanguageSwitcher';
import { useTranslation } from 'react-i18next';
import Form from './Form';

const Root: React.FC = () => {
  const { t } = useTranslation("common", { keyPrefix: "components.Root" });

  return (
    <>
      <LanguageSwitcher />

      <Container maxWidth="sm" sx={{ my: { xs: 2, sm: 4 } }}>
        <Card variant="outlined" sx={{ p: 3 }}>
          <Header />

          <Typography paragraph variant="body1" sx={{ mt: 4 }}>{t('greeting')}</Typography>
          <Typography paragraph variant="body1">{t('description')}</Typography>

          <Form />
        </Card>

        <Footer />
      </Container>
    </>
  );
};

export default Root;

