import React, { forwardRef, useRef, useEffect } from 'react';
import intlTelInput from 'intl-tel-input';
import { TextField } from '@mui/material';
import 'intl-tel-input/build/css/intlTelInput.css';
import './PhoneNumberInput.css';

const IntlTelInputWrapper = forwardRef(({
  initialValue,
  onChangeNumber,
  onChangeCountry,
  onChangeValidity,
  onChangeErrorCode,
  initOptions,
  value,
  ...props
}, ref) => {
  const inputRef = useRef(null);
  const itiRef = useRef(null);

  const update = () => {
    const num = itiRef.current.getNumber();
    const countryIso = itiRef.current.getSelectedCountryData().iso2;
    // note: this number will be in standard E164 format, but any container component can use
    // intlTelInputUtils.formatNumber() to convert this to another format
    // as well as intlTelInputUtils.getNumberType() etc. if need be
    onChangeNumber?.(num);
    onChangeCountry?.(countryIso);

    if (itiRef.current.isValidNumber()) {
      onChangeValidity?.(true);
      onChangeErrorCode?.(null);
    } else {
      const errorCode = itiRef.current.getValidationError();
      onChangeValidity?.(false);
      onChangeErrorCode?.(errorCode);
    }
  };

  useEffect(() => {
    // store a reference to the current input ref, which otherwise is already lost in the cleanup function
    const inputRefCurrent = inputRef.current;
    itiRef.current = intlTelInput(inputRef.current, initOptions);
    inputRefCurrent.addEventListener("countrychange", update);
    return () => {
      try {
        inputRefCurrent.removeEventListener("countrychange", update);
        itiRef.current.destroy();
      } catch { /* no-op */ }
    };
  }, []);

  useEffect(() => {
    itiRef.current?.setNumber(value || '')
  }, [value]);

  // implement `InputElement` interface
  React.useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef.current.focus();
    },
  }));

  return (
    <input
      {...props}
      ref={inputRef}
      onInput={update}
      defaultValue={initialValue}
    />
  );
});

const PhoneNumberInput = forwardRef(({
  initialValue,
  onChangeNumber,
  onChangeCountry,
  onChangeValidity,
  onChangeErrorCode,
  initOptions,
  ...props
}, ref) => {
  return (
    <TextField
      type="tel"
      InputProps={{
        inputComponent: IntlTelInputWrapper,
        inputProps: {
          initialValue,
          onChangeNumber,
          onChangeCountry,
          onChangeValidity,
          onChangeErrorCode,
          initOptions,
          ref,
        },
        sx: {
          '& .iti': { display: 'inline-flex', width: '100%' },
          '& input.iti__tel-input': { flex: 1 },
          ...props.sx,
        },
      }}
      {...props}
    />
  );
});

export default PhoneNumberInput;
