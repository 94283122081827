/**
 * @example const { env } = applicationData;
 */
export const applicationData = JSON.parse(document.querySelector('meta[name=x-application-data]')?.content || '{}');

/**
 * CSRF token
 */
export const csrfToken = document.querySelector('meta[name=csrf-token]')?.content;
export const csrfParam = document.querySelector('meta[name=csrf-param]')?.content;

/**
 * Boolean flag indicating whether it's served on production
 */
export const production = (applicationData.env == "production");
