import React from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Select,
  MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import InfoIcon from '@mui/icons-material/InfoOutlined';

const MultipleRooms: React.FC = ({setGuestBook, selectedIndex}) => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.MultipleRooms' });
  const { reservation, guestBooks } = useReservationContext();

  if (reservation?.numberOfRooms > 1) {
    let ongoings = guestBooks.filter(g => g.status != 'ready').length;
    let cmpletion = guestBooks.filter(g => g.status == 'ready').length;

    return (
      <Card sx={{ my: 2 }}>
        <CardContent>
          <Box justifyContent="space-between">
            <Typography variant="body2"><InfoIcon/>{t('label')}</Typography>
            <Typography variant="body2">{t('description')}</Typography>
          </Box>

          <Box display="flex" justifyContent="space-between">
            <Typography variant="body2">{t('ongoing')}:</Typography>
            <Typography variant="h3">{ongoings}</Typography>
            <Typography variant="body2">{t('complete')}:</Typography>
            <Typography variant="h3">{cmpletion}</Typography>
          </Box>


          <Box justifyContent="space-between">
            <Typography variant="caption" paragraph mb={0}>{t('selectedRoom')}</Typography>
            <Select defaultValue={0} value={selectedIndex || 0} onChange={e => setGuestBook(e.target.value)}>
              { guestBooks?.map((guestBook, index) => (
                <MenuItem
                  value={index}
                  key={index}>
                  {t('roomIndex', {n: index + 1})}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return null;
};

export default MultipleRooms;

