export default {
  "Reservation information": "예약 정보",
  "Guest information": "게스트 정보",
  "cancel": "취소",
  "save": "저장",
  "yes": "예",
  "no": "아니오",
  "back": "뒤로",
  "today": "오늘",
  "components": {
    "ReservationSummary": {
      "title": "예약 내용",
      "failed": "예약 정보를 가져오는 데 실패했습니다. 페이지를 새로고침하고 다시 시도하십시오.",
      "name": "예약자명",
      "bookingNumber": "예약 번호",
      "property": "숙박 시설",
      "numberOfRooms": "예약 객실 수",
      "numberOfGuests": "숙박인원",
      "checkin": "체크인",
      "checkout": "체크아웃",
    },
    "LockScreen": {
      "greeting": "Rakuten STAY에 오신 것을 환영합니다\n\n체크인 전에 여기에서 숙박자 정보를 등록해 주세요.",
      "found": "온라인 체크인이 가능한 예약이 확인되었습니다.\n\n예약 내용에 문제가 없다면, 온라인 체크인을 시작해주세요.",
      "Form": {
        "errorMessage": {
          "required": "유효한 패스코드를 입력해주세요.",
          "invalid": "유효하지 않은 비밀 코드입니다.",
        },
        "passCode": "비밀 코드",
        "helperText": "이메일이나 메시지에 기재된 비밀 코드를 입력하세요.",
        "submit": "제출",
        "register": "숙박자 정보 등록으로 이동",
      },
      "productName": "ONLINE CHECK-IN",
    },
    "Root": {
      "greeting": "Rakuten STAY에 오신 것을 환영합니다",
      "description": "예약 번호를 입력하여 온라인 체크인이 가능한 예약을 검색하세요.",
      "Form": {
        "errorMessage": {
          "required": "예약 번호를 입력해주세요.",
          "notFound": "온라인 체크인이 가능한 예약을 찾을 수 없습니다. 예약 번호가 정확한지 확인해주세요.",
        },
        "property": "숙박 시설",
        "bookingNumber": "예약 번호",
        "bookingNumberHelp": "예약 사이트에서 발급된 예약 번호를 입력하세요. 예약 번호는 확인 이메일에 기재되어 있습니다.",
        "submit": "예약 확인",
      },
    },
    "AnnotationBanner": {
      "p1": "이 서비스는 현재 시험 운영 중이므로 일본 국적이거나 일본에 거주하는 개인에게만 제공됩니다.",
      "p2": "게스트 중에 일본 외국 국적이면서 일본에 주소가 없는 경우 기존의 체크인 방법이 적용되므로 여권을 등록하고 게스트 명부를 사전 등록하려면 다음 링크에 액세스하십시오.",
      "preEntry": "게스트 명부 사전 등록 (기존 방법)",
    },
    "Footer": {
      "privacyPolicy": {
        "label": "개인 정보 보호 정책",
        "href": "https://corp.stay.rakuten.co.jp/privacy-en.html",
      },
      "agreement": {
        "label": "Rakuten STAY 숙박약관",
        "href": "https://stay.rakuten.co.jp/agreement/ko.html"
      },
    },
  },
  "checkinStatus": {
    "pre-entry": "숙박자 정보 등록 중",
    "submitted": "숙박자 정보 등록 완료",
    "checked-in": "체크인 완료",
    "checked-out": "체크아웃 완료",
  },
  "numWithUnit": {
    "room_one": "{{count}} 실",
    "room_other": "{{count}} 실",
    "guest_one": "{{count}} 명",
    "guest_other": "{{count}} 명",
  },
};
