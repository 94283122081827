export default {
  "title": {
    "primary" : "代表者",
    "companion" : "同行者_{{count}}",
  },
  "section1": {
    "title": {
      "primary": "代表者は日本人もしくは、日本国内に住所を有していますか？",
      "companion": "登録対象者は日本人もしくは、日本国内に住所を有していますか？",
    },
    "caption": "日本人ではない、かつ日本国内に住所を有さない方はパスポートの登録が必要です。"
  },
  "section2": {
    "title": {
      "primary" : "代表者の本人情報",
      "companion" : "同行者_{{count}}の本人情報",
    },
  },
  "section3": {
    "title": "チェックイン完了通知メール設定",
    "description": "チェックイン完了時にメールでお部屋の入室方法（部屋入室用の暗証番号、部屋番号）を受けとることができます。",
    "caption": "これらの情報は、チェックイン端末で一時的に表示できますが忘れてしまった時のためにメールで受け取っておくと安心です。この設定は代表者のみ設定可能です。"
  },
  "guest": {
    "name": {
      "label": "氏名（必須）",
      "placeholder": "楽天 太郎",
      "errors": {
        "required": "氏名を入力してください。",
      },
    },
    "address": {
      "label": "住所（必須）",
      "placeholder": "東京都世田谷区玉川1-14-1",
      "errors": {
        "required": "住所を入力してください。",
      },
    },
    "phoneNumber": {
      "label": "連絡先電話番号（必須）",
      "defaultCountry": "jp",
      "errors": {
        "required": "連絡先電話番号を入力してください。",
        "invalid": "正しい電話番号を入力してください。",
      },
    },
    "checkinNotification": {
      "label": "メールを受信しますか？（必須）",
      "errors": {
        "required": "どちらか選択してください。",
      },
    },
    "email": {
      "label": "メールアドレス（必須）",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "メールアドレスを入力してください。",
        "invalid": "正しいメールアドレスを入力してください。",
      },
    },
  },
  "guest_type_menu": {
    "caption": "この宿泊者について、当てはまる方を選んでください。",
    "japanese_title": "日本人および日本に住所のある方",
    "japanese_caption": "海外に居住している日本人、日本に居住している外国人を含む",
    "foreigner_title": "日本に住んでいない外国人",
    "foreigner_caption": "外国人旅行者など"
  },
  "components": {
    "CameraSelfie": {
      "register": "顔登録",
      "register_photo": "顔情報を登録する",
      "update": "再登録",
      "submitted": "顔画像登録済み",
      "failed": "顔画像の登録状況を取得できませんでした。ページの再読込みをお願いします。",
      "instruction_1": "{{name}}さんの顔写真を撮影します。",
      "instruction_2": "マスクや帽子などが顔を覆っていないことを確認してください。",
      "cancel": "キャンセル",
      "capture": "撮影する",
      "captureAgain": "再撮影",
      "useThisPhoto": "この写真を使う",
      "captureNoteSelfie": "本人以外の人の顔が写真に映り込んでいないこと、マスクや帽子などが顔を覆っていないことをご確認ください。",
      "upload_success": "アップロード完了"
    },
    "CameraPassport": {
      "title": "パスポート情報",
      "caption": "パスポートの写真を撮影いただき、その画像からパスポート情報をスキャンします。お手元にパスポートをご用意ください。",
      "register": "パスポートを登録する",
      "update": "変更",
      "instruction": "パスポートの顔写真が載っているページが枠内に収まるように写真を撮影してください。",
      "captureNotePassport": "顔写真および文字が鮮明に写っていることをご確認の上、パスポート情報のスキャンをしてください。",
      "scan": "スキャン",
      "scanSuccess": "パスポートのスキャンが完了しました。スキャン内容に間違いがないことをご確認ください。",
      "registerContent": "この内容で登録",
      "cancel": "キャンセル",
      "capture": "撮影する",
      "captureAgain": "再撮影",
      "scanFailed": "パスポートのスキャンに失敗しました。",
      "scanFailedInstruction": "再撮影をしていただくか、何度も失敗する場合にはパスポート情報を手動で登録してください",
      "takeAnotherPhoto": "再撮影する",
      "addManually": "パスポート情報を入力する",
      "error": {
        "failedScan": "パスポートのスキャンに失敗しました。"
      }
    },
    "PassportForm": {
      "title": "パスポート情報",
      "number": "パスポート番号（必須）",
      "name": "氏名（必須）",
      "country": "国籍（必須）",
      "countrySelect": "国を選択",
      "expiryDate": "有効期限（必須）",
      "caption": "撮影したパスポートに記載されている通りの情報を入力してください。",
      "save": "保存する",
      "year": "年",
      "month": "月",
      "day": "日",
      "validation": {
        "numberRequired": "パスポート番号を入力してください",
        "nameRequired": "氏名を入力してください",
        "countryRequired": "国籍を選択してください",
        "expiryDateRequired": "有効期限を選択してください",
        "yearRequired": "年を選択してください",
        "monthRequired": "月を選択してください",
        "dayRequired": "日を選択してください",
        "addressRequired": "住所を入力してください",
        "phoneNumberRequired": "電話番号を入力してください",
      },
      "primaryGuestInfo": "代表者の本人情報",
      "accompanyGuestInfo": "同行者_{{index}}の本人情報",
      "updatePhoto": "再登録",
      "address": "住所（必須）",
      "sameAs": "と同じ",
      "phoneNumber": "電話番号（必須）",
      "guestSelectBox": "他の宿泊者を選択",
      "cancel": "キャンセル",
      "error": {
        "updateGuestFailed": "ゲスト情報を更新できませんでした。詳細についてはホストにお問い合わせください。",
        "invalidPhoneNumber": "正しい電話番号を入力してください",
      }
    }
  },
  "sameWithPrimaryGuest": "代表者と同じ",
  "register": "登録する"
};
