import ApplicationModel from '@src/models/ApplicationModel';
import Guest from '@src/models/Guest';

export default class GuestBook extends ApplicationModel {
  constructor(attrs = {}) {
    super(attrs);

    this.guests = (this.guests || []).map(g => new Guest(g));

    while (this.guests.length < (this.numberOfGuests || 1)) {
      this.guests.push(new Guest);
    }

    this.checkinAt &&= new Date(this.checkinAt);
    this.checkoutAt &&= new Date(this.checkoutAt);
  }

  get primaryGuest() {
    return this.guests.filter(({ role }) => role == "primary")[0];
  }

  get numberOfCompanions() {
    return this.numberOfGuests - 1;
  }

  get companions() {
    return this.guests.slice(1, this.numberOfGuests);
  }

  get registeredGuests() {
    return this.guests.filter(g => g.ready).length;
  }

  get checkinCodeSVG() {
    return `/${this.reservationHashedId}/${this.hashedId}.svg`;
  }
}
