import { lazy } from "react";
import { createRoutesFromElements, Outlet, Route, useParams } from 'react-router-dom';
import ApplicationLayout from '@src/layouts/ApplicationLayout';
import { ReservationProvider } from '@src/providers/ReservationProvider';
import { GuestBookProvider } from '@src/providers/GuestBookProvider';
import Root from '@src/routes/Root';
import Entrance from '@src/routes/Entrance';
import GuestForm from '@src/routes/GuestForm';

const Checkout = lazy(() => import('@src/routes/Checkout'));

const EntranceHandler = () => {
  const { id } = useParams();

  if (/^\d{1,5}$/.test(id)) {
    return <Root />;
  }

  return <ReservationProvider />;
}

const routes = createRoutesFromElements(<>
  <Route path="/" element={<ApplicationLayout />} errorElement={<ApplicationLayout notFound />}>
    <Route index element={<Root />} />

    <Route path="checkout" element={<Outlet />}>
      <Route index element={<Checkout />} />
      <Route path=":roomId" element={<Checkout />} />
    </Route>

    <Route path=":id" element={<EntranceHandler />}>
      <Route index element={<Entrance />} />

      <Route path=":guestBookId" element={<GuestBookProvider />}>
        <Route index element={<GuestForm primary />} />
        <Route path="primary-guest" element={<GuestForm primary />} />
        <Route path="guests/:guestIndex" element={<GuestForm />} />
      </Route>
    </Route>
  </Route>
</>)

export default routes;
