export default {
  "title": {
    "primary": "주 투숙객",
    "companion": "동반 투숙객_{{count}}",
  },
  "section1": {
    "title": {
      "primary": "주 투숙객은 일본 국적이거나 일본에 주소가 있습니까?",
      "companion": "등록 대상자는 일본 국적이거나 일본에 주소가 있습니까?",
    },
    "caption": "일본 국적이 아니거나 일본에 주소가 없는 경우, 여권 등록이 필요합니다.",
  },
  "section2": {
    "title": {
      "primary": "주 투숙객 개인 정보",
      "companion": "동반 투숙객_{{count}} 의 개인 정보",
    },
  },
  "section3": {
    "title": "체크인 완료 알림 이메일 설정",
    "description": "체크인이 완료되면 객실 입장 방법 (객실 입장 PIN, 객실 번호)에 대한 정보를 이메일로 받을 수 있습니다.",
    "caption": "이 정보는 일시적으로 체크인 단말에 표시될 수 있지만, 잊어버릴 경우를 대비하여 이메일로 받는 것이 안심입니다. 이 설정은 주 투숙객에게만 가능합니다.",
  },
  "guest": {
    "name": {
      "label": "이름 (필수)",
      "placeholder": "라쿠텐 타로",
      "errors": {
        "required": "이름을 입력하세요",
      },
    },
    "address": {
      "label": "주소 (필수)",
      "placeholder": "도쿄 도 세타가야구 타마가와 1-14-1",
      "errors": {
        "required": "주소를 입력하세요",
      },
    },
    "phoneNumber": {
      "label": "연락처 전화번호 (필수)",
      "defaultCountry": "ko",
      "errors": {
        "required": "연락처 전화번호를 입력하세요",
        "invalid": "올바른 전화번호를 입력하세요",
      },
    },
    "checkinNotification": {
      "label": "이메일을 수신하시겠습니까? (필수)",
      "errors": {
        "required": "선택하세요",
      },
    },
    "email": {
      "label": "이메일 주소 (필수)",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "이메일 주소를 입력하세요",
        "invalid": "올바른 이메일 주소를 입력하세요",
      },
    },
  },
  "guest_type_menu": {
    "caption": "이 숙박자에 대해 해당하는 항목을 선택해 주세요.",
    "japanese_title": "일본인 및 일본에 주소가 있는 분",
    "japanese_caption": "해외에 거주하는 일본인, 일본에 거주하는 외국인을 포함",
    "foreigner_title": "일본에 거주하지 않는 외국인",
    "foreigner_caption": "외국인 여행자 등"
  },
  "components": {
    "CameraSelfie": {
      "register": "얼굴 등록",
      "register_photo": "얼굴 정보를 등록하기",
      "update": "재등록",
      "submitted": "얼굴 이미지 등록 완료",
      "failed": "얼굴 이미지 등록 상태를 가져올 수 없습니다. 페이지를 다시 로드해 주세요.",
      "instruction_1": "{{name}} 씨의 얼굴 사진을 촬영합니다.",
      "instruction_2": "마스크나 모자 등으로 얼굴이 가려지지 않았는지 확인해 주세요.",
      "cancel": "취소",
      "capture": "촬영하기",
      "captureAgain": "재촬영",
      "useThisPhoto": "이 사진 사용",
      "captureNoteSelfie": "본인 외 다른 사람이 사진에 찍히지 않았는지, 마스크나 모자 등으로 얼굴이 가려지지 않았는지 확인해 주세요.",
      "upload_success": "업로드 완료"
    },
    "CameraPassport": {
      "title": "여권 정보",
      "caption": "여권 사진을 촬영하여 그 이미지에서 여권 정보를 스캔합니다. 여권을 준비해 주세요.",
      "register": "여권 등록",
      "update": "변경",
      "instruction": "여권의 얼굴 사진이 있는 페이지가 프레임 안에 들어오도록 사진을 찍어 주세요.",
      "captureNotePassport": "얼굴 사진 및 문자가 선명하게 찍혔는지 확인한 후 여권 정보를 스캔해 주세요.",
      "scan": "스캔",
      "scanSuccess": "여권 스캔이 완료되었습니다. 스캔 내용에 오류가 없는지 확인해 주세요.",
      "registerContent": "이 내용으로 등록",
      "cancel": "취소",
      "capture": "촬영",
      "captureAgain": "재촬영",
      "scanFailed": "여권 스캔에 실패했습니다.",
      "scanFailedInstruction": "재촬영하거나 여러 번 실패할 경우 여권 정보를 수동으로 등록해 주세요.",
      "takeAnotherPhoto": "재촬영",
      "addManually": "여권 정보 입력",
      "error": {
        "failedScan": "여권 스캔에 실패했습니다."
      }
    },
    "PassportForm": {
      "title": "여권 정보",
      "number": "여권 번호 (필수)",
      "name": "이름 (필수)",
      "country": "국적 (필수)",
      "countrySelect": "국가 선택",
      "expiryDate": "유효 기간 (필수)",
      "caption": "촬영한 여권에 기재된 대로 정보를 입력해 주세요.",
      "save": "저장",
      "year": "년",
      "month": "월",
      "day": "일",
      "validation": {
        "numberRequired": "여권 번호를 입력해 주세요",
        "nameRequired": "이름을 입력해 주세요",
        "countryRequired": "국적을 선택해 주세요",
        "expiryDateRequired": "유효 기간을 선택해 주세요",
        "yearRequired": "년도를 선택해 주세요",
        "monthRequired": "월을 선택해 주세요",
        "dayRequired": "일을 선택해 주세요",
      },
      "primaryGuestInfo": "주 투숙객 정보",
      "accompanyGuestInfo": "동반 투숙객_{{index}} 정보",
      "updatePhoto": "사진 변경",
      "address": "주소 (필수)",
      "sameAs": "와 동일",
      "phoneNumber": "전화번호 (필수)",
      "guestSelectBox": "다른 투숙객 선택",
      "cancel": "취소",
      "error": {
        "updateGuestFailed": "투숙객 정보 업데이트에 실패했습니다. 자세한 내용은 호스트에게 문의해 주세요.",
        "invalidPhoneNumber": "올바른 전화번호를 입력해 주세요",
      }
    }
  },
  "sameWithPrimaryGuest": "주 투숙객과 동일",
  "register": "등록",
};
