import ApplicationModel from '@src/models/ApplicationModel';

export default class Pagination extends ApplicationModel {
  get firstOfPage() {
    return ((this.page - 1) * this.per) + 1;
  }

  get lastOfPage() {
    return Math.min(this.page * this.per, this.total);
  }

  get summary() {
    return {
      total: this.total?.toLocaleString(),
      firstOfPage: this.firstOfPage?.toLocaleString(),
      lastOfPage: this.lastOfPage?.toLocaleString(),
    }
  }

  /**
   * Alias for `per`
   */
  get pageSize() {
    return this.per;
  }

  /**
   * Used as an initialState of DataGrid
   */
  get model() {
    const { page, pageSize } = this;
    return { page: page - 1, pageSize };
  }
}
