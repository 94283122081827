import { Button, Stack, Typography } from "@mui/material";
import CustomIcon from "../ui/CustomIcon";


const GuestTypeSelectButton = ({
  onClick, iconVariant, label, description
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void,
  iconVariant: string,
  label: string,
  description: string,
}) => {
  return (
    <Button
      variant='bordered'
      onClick={onClick}
      sx={{
        borderRadius: 3,
        borderWidth: 2,
        p: 3,
        '&:hover': {
          borderWidth: 2
        }
      }}
    >
      <Stack spacing={1} sx={{ alignItems: 'center' }}>
        <CustomIcon variant={iconVariant} color='green' width={40} sx={{ m: '0 auto' }}/>
        <Typography variant='label3Bold'>
          {label}
        </Typography>
        <Typography variant='body2'>
          {description}
        </Typography>
      </Stack>
    </Button>
  );
}

export default GuestTypeSelectButton;
