import { Button } from '@mui/material';
import { CameraAlt } from '@mui/icons-material';
import { MouseEventHandler } from 'react';

const CameraButton = ({
  children,
  onClick,
  ...rest
}: {
  children: React.ReactNode,
  onClick: MouseEventHandler
}) => {
  return (
    <Button
      fullWidth
      startIcon={<CameraAlt />}
      sx={{
        fontSize: 16,
        lineHeight: 1.25,
        minHeight: 64,
        borderRadius: 2,
        border: '1px dashed #507763',
        background: '#F0F5F3',
        color: '#507763',
        fontWeight: 'bold',
        '&:hover': {
          background: '#D1E0DA',
        },
      }}
      onClick={onClick}
      {...rest}
    >
      { children }
    </Button>
  );
};

export default CameraButton;
