import React, { useEffect, useRef } from 'react';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const Fallback: React.FC = ({ error, componentStack, resetError }) => {
  const { t } = useTranslation();
  const [summary] = (error.message || error).toString().split("\n");
  const ref = useRef(null);

  useEffect(() => {
    if (import.meta.hot) {
      import.meta.hot.on("vite:afterUpdate", () => {
        setTimeout(() => { ref.current?.click() }, 30);
      });
    }
  }, []);

  const resetButton = <Button variant="outlined" onClick={resetError} ref={ref}>
    <Typography noWrap>{t('Retry')}</Typography>
  </Button>;

  return (
    <Box my={4}>
      <Alert severity="error" action={resetButton}>
        <Typography>{t('An error occurred.')}</Typography>
        <Box my={2}>{summary}</Box>
      </Alert>
    </Box>
  );
};

export default Fallback;
