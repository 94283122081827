import React, { useEffect } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { createContext, useContext } from 'react';
import { useAPI, useMutationAPI } from '@src/util/api';
import { useQuery } from '@src/util/router';
import Reservation from '@src/models/Reservation';
import Root from '@src/routes/Root';

const Context = createContext({});

export const ReservationProvider: React.FC = props => {
  const { code } = useQuery();
  const { id } = useParams();
  const { data: reservation, ...rest } = useAPI(
    `/v1/checkin/reservations/${id}`,
    { model: Reservation }
  );

  const { trigger } = useMutationAPI(
    `/v1/checkin/reservations/${id}`,
    { model: Reservation }
  );

  const update = (body) => trigger({ method: 'PATCH', body });
  const unlock = (query) => trigger({ method: 'GET', query });
  const reload = () => trigger({ method: 'GET' });

  useEffect(() => {
    if (code) { unlock({ code }); }
  }, [code]);

  const { property, roomGroup, guestBooks } = reservation || {};

  const value = {
    reservation, property, roomGroup, guestBooks, update, unlock, reload, ...rest
  }

  return <Context.Provider value={value} {...props}>
    <Outlet />
  </Context.Provider>;
};

export const useReservationContext = () => useContext(Context);

