import { createContext, useContext } from 'react';
import { useParams, Outlet } from 'react-router-dom';
import { useMutationAPI } from '@src/util/api';
import { useReservationContext } from '@src/providers/ReservationProvider';
import LoadingBackdrop from '@src/components/ui/LoadingBackdrop';
import GuestBook from '@src/models/GuestBook';
import countries from '../countries';

const Context = createContext({});

export const GuestBookProvider: React.FC = ({ guestBook, children, ...props }) => {
  const { reservation, mutate, isLoading } = useReservationContext();
  const { guestBookId, guestIndex } = useParams();
  guestBook ||= reservation?.findGuestBook({ hashedId: guestBookId });

  const { trigger, ...rest } = useMutationAPI(
    `/v1/checkin/guest_books/${guestBook?.hashedId}`,
    { model: GuestBook }
  );

  const updateGuestBook = (body) => {
    const req = { method: 'PATCH', body };

    return trigger(req).then((guestBook) => {
      reservation.updateGuestBook(guestBook);
      mutate(reservation);
    });
  };

  const updateCurentGuest = (params) => {
    const baseParams = {
      countryCode: findCountryCode(params.countryCode),
      name: params.name
    };

    const guestParams = {
      guests: currentGuest().role === 'primary'?
      [{ ...baseParams, hashed_id: primaryGuest().hashedId }]:
      [
        { hashedId: primaryGuest().hashedId },
        { ...baseParams, hashedId: currentGuest().hashedId }
      ]
    };

    updateGuestBook(guestParams)
  }

  const primaryGuest = () => { return guestBook.guests[0] }

  const currentGuest = () => {
    if (guestIndex) { return guestBook.guests[guestIndex] }

    return guestBook.guests[0];
  }

  const findCountryCode = (name) => {
    return countries.find(c => c.label === name)?.code.toLowerCase()
  }

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  return <Context.Provider value={{ guestBook, currentGuest, updateCurentGuest, updateGuestBook, ...rest }} {...props}>
    {children || <Outlet />}
  </Context.Provider>;
};

export const useGuestBookContext = () => useContext(Context);
