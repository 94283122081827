import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { applicationData } from '@src/util/environment';
import { en, ja, ko, zh } from './common';
import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh';

export const FALLBACK_LANG = 'en';

const resources = {
  en: { common: en },
  ja: { common: ja },
  ko: { common: ko },
  "zh-CN": { common: zh },
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: applicationData.locale,
    fallbackLng: FALLBACK_LANG,
    defaultNS: 'common',
    fallbackNS: 'common',
    react: {
      useSuspense: true
    },
    interpolation: {
      escapeValue: false
    }
  });

dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.locale(i18n.language.split(/[-_]/)[0]);

export default i18n;
