import React, { useState, useRef, useEffect } from "react";
import { Grid, Box, Dialog, DialogContent, DialogContentText, ListItem, Typography, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubmitButton from '@src/components/buttons/SubmitButton';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import { useReservationContext } from '@src/providers/ReservationProvider';
import Guest from '@src/models/Guest';
import { useMutationAPI } from '@src/util/api';
import CameraButton from '../../components/buttons/CameraButton';
import MessageBar from "../../components/ui/MessageBar";
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';
import CameraAltIcon from '@mui/icons-material/CameraAlt';

const CameraSelfie: React.FC = () => {
  const { t } = useTranslation('GuestForm', { keyPrefix: 'components.CameraSelfie' });
  const { guestBook, guestBook: { primaryGuest } = {}} = useGuestBookContext();
  const { reload } = useReservationContext();
  const [showCapture, setShowCapture] = useState(false);
  const camera = useRef(null);
  const [image, setImage] = useState('');
  const [showCamera, setShowCamera] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [successMsg, setSuccessMsg] = useState(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const { trigger } = useMutationAPI(
    `/v1/checkin/guest_books/${guestBook.hashedId}/guests/${primaryGuest.hashedId}/photos`,
    { model: Guest }
  );

  const handleClickOpen = () => {
    setShowCamera(true);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setShowCamera(false);
    setOpenDialog(false);
    setImage(null)
    setShowCapture(false)
  };

  function moveToCameraScreen() {
    setImage(null)
    setShowCapture(false)
  }

  function capturePhoto() {
    const defaultButton = document.getElementById('outer-circle');
    if (defaultButton) { defaultButton.click(); }
  }

  function useThisPhoto() {
    const body = { base64: image, type: 'pre-registration' }

    return trigger({ method: 'POST', body }).then(() => {
      handleCloseDialog();
      setSuccessMsg(t('upload_success'));
      reload();
    });
  }

  function handleTakePhoto (dataUri) {
    setImage(dataUri);
    setShowCapture(true)
  }

  return (
    <>
      {guestBook.checkinStatus === 'submitted' ?
        <Button variant='white' startIcon={<CameraAltIcon />} onClick={handleClickOpen} size="small" sx={{ height: 40 }}>
          {t('update')}
        </Button> :
        <Button variant='white' startIcon={<CameraAltIcon />} onClick={handleClickOpen}>
          {t('register_photo')}
        </Button>
      }

      <Dialog
        fullScreen
        open={openDialog}
        onClose={handleCloseDialog}
      >
        <DialogContent sx={{ background: '#f5f5f5', p: 0 }}>
          {!showCapture &&
            <Stack
              sx={{
                p: 3,
                maxWidth: 648,
                margin: '24px auto',
                position: 'relative',
                background: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: 3,
                '.react-html5-camera-photo': {
                  height: '100vw',
                  maxHeight: 600,
                  '.display-error': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    position: 'absolute',
                    fontSize: '6px',
                    padding: '2px',
                    zIndex: 1,
                    width: '100%',
                  }
                },
                'video': {
                  objectFit: 'cover',
                  background: '#fff',
                  width: '100%',
                  height: '100%',
                  '@media (max-width: 600px)': {
                    height: '100vw',
                  }
                },
                '@media (max-width: 648px)': {
                  p: 0,
                  pb: 2,
                  borderRadius: 0,
                  margin: 0,
                  borderBottom: '1px solid #ddd',
                  boxShadow: 'none',
                }
              }}
            >
              <Camera
                id="camera"
                onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                isFullscreen={false}
                idealResolution = {{ width: 900, height: 900 }}
                imageType={IMAGE_TYPES.JPG}
                idealFacingMode={FACING_MODES.USER}
                isImageMirror={true}
                isSilentMode={true}
                isMaxResolution={false}
              />
              <Stack spacing={2} sx={{ pt: 2, '@media (max-width: 648px)': { p: 2, pb: 1} }}>
                <Stack spacing={1}>
                  <Typography variant="label2Bold">{t('instruction_1', { name: primaryGuest.name })}</Typography>
                  <Typography variant="label4">{t('instruction_2')}</Typography>
                </Stack>
                <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                  <Button fullWidth variant="secondary" onClick={handleCloseDialog}>{t('cancel')}</Button>
                  {image
                    ? <SubmitButton fullWidth onClick={useThisPhoto}>{ t('useThisPhoto') }</SubmitButton>
                    : <SubmitButton fullWidth startIcon={<CameraAltIcon />} onClick={capturePhoto}>{t('capture')}</SubmitButton>
                  }
                </Stack>
              </Stack>
            </Stack>
          }

          {showCamera && showCapture &&
            <Stack
              spacing={2}
              sx={{
                p:3,
                alignItems: 'center',
                maxWidth: 648,
                margin: '24px auto',
                position: 'relative',
                background: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: 3,
                '@media (max-width: 648px)': {
                  p: 0,
                  borderRadius: 0,
                  m: 0
                },
              }}
            >
              <Box component="img"
                alt="Selfie"
                src={image}
                style={{ width: "100%" }}
              />
              <Button
                variant="bordered"
                size="small"
                startIcon={<CameraAltIcon />}
                onClick={moveToCameraScreen}
              >
                {t('captureAgain')}
              </Button>
              <Stack spacing={2} sx={{ '@media (max-width: 648px)': { p: 2, pt: 0 } }}>
                <Typography variant="body1">{ t('captureNoteSelfie') }</Typography>
                <Stack spacing={2} direction="row" sx={{ width: '100%', py: 1 }}>
                  <Button fullWidth variant="secondary" onClick={handleCloseDialog}>{t('cancel')}</Button>
                  <SubmitButton fullWidth onClick={useThisPhoto}>{ t('useThisPhoto') }</SubmitButton>
                </Stack>
              </Stack>
            </Stack>
          }
        </DialogContent>
      </Dialog>
      { successMsg && <MessageBar message={successMsg} severity='success' />}
    </>
  );
};

export default CameraSelfie;
