/**
 * Rename `ref` to `inputRef` to use react-hook-form with Material-UI component.
 */
export const inputRef = (props) => {
  const { ref, ...rest } = props;
  return { inputRef: ref, ...rest };
};

/**
 * Simple email regex
 */
export const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
