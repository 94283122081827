export default {
  "onlineCheckIn": "ONLINE CHECK-IN",
  "guestBook": "Guest Book",
  "guestBookIndex": "Guest List (Room {{n}})",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "Please complete the registration of all guest information before check-in.",
      },
      "submitted": {
        "message": "Pre-registration is ready. Check-in reception starts from {{checkinTime}}.",
      },
      "checked-in": {
        "message": "This reservation has been checked in.\n\nIf you forget your room number or room key, use the QR code or check-in number displayed on this screen to perform the re-display operation on the check-in terminal.",
      },
      "checked-out": {
        "message": "Thank you for staying at Rakuten STAY.\n\nWe look forward to serving you again.",
      },
    },
    "NumberOfGuests": {
      "total": "Total Guests: {{count}}",
      "registered": "<0>Registered: <1>{{count}}</1></0>",
      "description": "Please register the number of guests.",
      "caption": "Register information for all registered guests.",
      "edit": "Edit",
      "cancel" : "Cancel",
      "update": "Update",
      "numberOfGuestsWithCount_one": "{{count}} guest",
      "numberOfGuestsWithCount_other": "{{count}} guests",
      "warning": {
        "title": "Attention",
        "description": "The price will not change depending on the number of guests.\nAlso, please note that the quantity of meals you have already ordered will not change.\nIf you reduce the number of guests, any information already registered may be discarded."
      },
    },
    "PrimaryGuest": {
      "title": "Primary Guest",
      "register": "Register",
      "update": "Edit",
    },
    "CheckinCode": {
      "description": "Please proceed with the check-in using this QR code or check-in number at the check-in terminal (SMART CHECK-IN).",
      "label": "Check-in Number",
      "ciQrCode": "Check-in QR",
      "photo": {
        "instruction_1": "On the day of check-in, you can use authentication to verify your identity on-site.",
        "instruction_2": "Register the representative's facial information and complete online check-in.",
        "submitted": "Facial photo registered",
      },
    },
    "AccompanyingGuests": {
      "title_zero": "Accompanying Guest",
      "title": "Accompanying Guest_{{count}}",
      "register": "Register",
      "update": "Edit",
    },
    "MultipleRooms": {
      "label": "Multiple rooms have been reserved.",
      "description": "Please check in online for each room separately as check-in is required for each room.",
      "ongoing": "Incomplete",
      "complete": "Complete",
      "selectedRoom": "Selected Room",
      "roomIndex": "{{n}}th room"
    }
  },
};
