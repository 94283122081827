import i18n from '@src/i18n';

import en from './en';
import ja from './ja';
import ko from './ko';
import zh from './zh';

i18n.addResourceBundle('en', 'Entrance', en);
i18n.addResourceBundle('ja', 'Entrance', ja);
i18n.addResourceBundle('ko', 'Entrance', ko);
i18n.addResourceBundle('zh-CN', 'Entrance', zh);
