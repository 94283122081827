import React from 'react';
import {
  AppBar,
  Box,
  Container,
  SxProps,
  Toolbar,
  Typography,
} from '@mui/material';
import logoSVG from '@src/assets/logo.svg';
import LanguageSwitcher from '@src/components/ui/LanguageSwitcher';
import { useTranslation } from 'react-i18next';

type Props = {
  logo?: boolean,
  color?: any,
  children?: React.ReactNode,
  sx?: SxProps,
}

const Header = ({ logo, color='white', children, sx, ...props }: Props) => {
  const { t } = useTranslation('Entrance');

  return (
    <>
      <LanguageSwitcher />
      <AppBar position="sticky" elevation={2} sx={{...sx}} {...props} color={color}>
        <Toolbar
          sx={{
            px: 0,
            minHeight: {
              sm: 56
            },
            p: {
              sm: 0
            }
          }}
        >
          <Container maxWidth="sm" sx={{ pl: 1 }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {logo && (
                <>
                  <Box
                    component="img"
                    sx={{ height: 48 }}
                    alt="Rakuten STAY"
                    src={logoSVG}
                  />
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{
                      fontWeight: 'bold',
                      flex: 1,
                      textAlign: 'right'
                    }}>
                    {t('onlineCheckIn')}
                  </Typography>
                </>
              )}
              {children}
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
