import { MouseEventHandler, ReactNode } from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const SubmitButton = ({
  children, loading, startIcon, endIcon, ...props
}: {
  children: ReactNode
  loading?: boolean
  startIcon?: ReactNode
  endIcon?: ReactNode
}) => (
  <Button
    variant='primary'
    type="submit"
    disabled={loading}
    startIcon={loading ? null : startIcon}
    endIcon={loading ? null : endIcon}
    {...props}
    sx={{
      opacity: loading ? 0.5 : 1,
      ...props.sx,
    }}
  >
    {loading ? <CircularProgress color='white' size={16} /> : children}
  </Button>
);

export default SubmitButton;
