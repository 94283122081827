import i18n from '@src/i18n';

/**
 * @example nl2br("Long\nLines")
 */
const newLine = /(\r?\n|\r)/g
export const nl2br = (str) => {
  if (typeof str != 'string') { return str; }
  return str.split(newLine).map((line, index) => (
    newLine.test(line) ? <br key={index} /> : line
  ));
};

/**
 * @example currency(2000, 'JPY')
 */
export const currency = (num, currency = 'JPY') => {
  return Intl.NumberFormat(i18n.language, { style: 'currency', currency }).format(num);
};

/**
 * @example ascii('ＡＢＣ');
 */
export const ascii = (str) => (
  (str || '').toString()
    .replace(/[Ａ-Ｚａ-ｚ０-９＋]/g, s => String.fromCharCode(s.charCodeAt(0) - 0xFEE0))
    .replace(/　/g, ' ')
    .replace(/。/g, '.')
    .replace(/[ーｰ－]/g, '-')
);
