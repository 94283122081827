import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import { useReservationContext } from '@src/providers/ReservationProvider';
import {
  FormControl,
  Typography,
  FormLabel,
  Grid,
  Container,
  Box,
  TextField,
  Autocomplete
 } from '@mui/material';
import SubmitButton from "../../components/buttons/SubmitButton";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMutationAPI } from '../../util/api';
import countries from '@src/countries';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';
import 'dayjs/locale/en';
import 'dayjs/locale/ko';
import 'dayjs/locale/zh';
import i18n from '@src/i18n';
import { DatePicker } from "@mui/x-date-pickers";

const PassportForm: React.FC = ({ setIsEditing, dialogClose }) => {
  const { t } = useTranslation('GuestForm', { keyPrefix: 'components.PassportForm' });
  const { guestBook, currentGuest } = useGuestBookContext();
  const { reload } = useReservationContext();
  const { control, handleSubmit, setValue, formState: { errors, isSubmitting } } = useForm({
    defaultValues: {
      name: '',
      number: '',
      country: null,
      year: dayjs(),
      month: dayjs(),
      day: dayjs(),
    }
  });
  const [year, setYear] = useState(dayjs());
  const [month, setMonth] = useState(dayjs());
  const [day, setDay] = useState(dayjs());

  const handleYearChange = (newValue) => {
    setYear(newValue);
    setMonth(newValue);
    setDay(newValue);
    setValue('year', newValue);
    setValue('month', newValue);
    setValue('day', newValue);
  };

  const handleMonthChange = (newValue) => {
    setMonth(newValue);
    setDay(newValue);
    setValue('month', newValue);
    setValue('day', newValue);
  };

  const handleDayChange = (newValue) => {
    setDay(newValue);
    setValue('day', newValue);
  };

  const [locale] = React.useState<LocaleKey>(i18n.language);
  const { trigger } = useMutationAPI(`/v1/checkin/guest_books/${guestBook.hashedId}/guests/${currentGuest().hashedId}/photos`);

  const onSubmit = (form) => {
    if (errors.length > 0) { return; }
    const { number, name, country } = form;
    const body = {
      name,
      countryCode: country.code,
      documentNumber: number,
      expiryDate: `${year.format('YYYY')}-${month.format('MM')}-${day.format('DD')}`
    }
    return trigger({ method: 'PUT', body, useSnakeCase: false }).then(() => {
      reload();
      dialogClose();
      setIsEditing(true);
    }).catch((err) => { console.log(err) });
  };

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)}>
      <Typography sx={{ mb: 2 }}>{ t('caption') }</Typography>
      <FormControl variant="outlined" fullWidth error={!!errors.number}>
        <FormLabel>{t('number')}</FormLabel>
        <Controller
          name='number'
          control={control}
          rules={{ required: t('validation.numberRequired') }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              error={!!errors.number}
              helperText={errors.number ? errors.number.message : ''}
            />
          )}
        />
      </FormControl>

      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }} error={!!errors.name}>
        <FormLabel>{t('name')}</FormLabel>
        <Controller
          name='name'
          control={control}
          rules={{ required: t('validation.nameRequired') }}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              error={!!errors.name}
              helperText={errors.name ? errors.name.message : ''}
            />
          )}
        />
      </FormControl>

      <FormControl variant="outlined" fullWidth sx={{ mt: 2 }} error={!!errors.country}>
        <FormLabel>{t('country')}</FormLabel>
        <Controller
          name='country'
          control={control}
          rules={{ required: t('validation.countryRequired') }}
          render={({ field }) => (
            <Autocomplete
              {...field}
              id="country-select"
              fullWidth
              options={countries}
              autoHighlight
              getOptionLabel={(option) => option.label || ''}
              isOptionEqualToValue={(option, value) => option.code === value.code}
              onChange={(e, value) => {
                field.onChange(value);
                setValue('country', value);
              }}
              renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                  <img loading="lazy" width="20"
                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                  />
                  {option.label} ({option.code})
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('countrySelect')}
                  inputProps={{ ...params.inputProps }}
                  error={!!errors.country}
                  helperText={errors.country ? errors.country.message : ''}
                />
              )}
              sx={{
                '.MuiOutlinedInput-root': {
                  p: 0,
                  '.MuiAutocomplete-input': {
                    p: 0,
                    px: 2
                  }
                }
              }}
            />
          )}
        />
      </FormControl>

      <FormControl fullWidth sx={{ mt: 2, border: 'none' }} error={!!errors.year || !!errors.month || !!errors.day}>
        <FormLabel>{t('expiryDate')}</FormLabel>
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={locale}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth error={!!errors.year}>
                <Controller
                  name="year"
                  control={control}
                  rules={{ required: t('validation.yearRequired') }}
                  render={({ field }) => (
                    <DatePicker
                      label="Year"
                      inputFormat="YYYY"
                      views={['year']}
                      value={year}
                      onChange={(value) => {
                        field.onChange(value);
                        handleYearChange(value);
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors.year,
                          helperText: errors.year ? errors.year.message : '',
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth error={!!errors.month}>
                <Controller
                  name="month"
                  control={control}
                  rules={{ required: t('validation.monthRequired') }}
                  render={({ field }) => (
                    <DatePicker
                      label="Month"
                      inputFormat="MM"
                      views={['month']}
                      value={month}
                      onChange={(value) => {
                        field.onChange(value);
                        handleMonthChange(value);
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors.month,
                          helperText: errors.month ? errors.month.message : '',
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth error={!!errors.day}>
                <Controller
                  name="day"
                  control={control}
                  rules={{ required: t('validation.dayRequired') }}
                  render={({ field }) => (
                    <DatePicker
                      label="Day"
                      inputFormat="DD"
                      views={['day']}
                      value={day}
                      onChange={(value) => {
                        field.onChange(value);
                        handleDayChange(value);
                      }}
                      slotProps={{
                        textField: {
                          error: !!errors.day,
                          helperText: errors.day ? errors.day.message : '',
                        },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          </Grid>
        </LocalizationProvider>
      </FormControl>

      <SubmitButton sx={{ width: '100%', mt: 4, mb: 1 }} loading={isSubmitting}>{ t('save') }</SubmitButton>
    </Box>
  );
};

export default PassportForm;
