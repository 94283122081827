import React from 'react';
import {
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import Header from './Header';
import Footer from '@src/components/ui/Footer';
import LanguageSwitcher from '@src/components/ui/LanguageSwitcher';
import Form from './Form';
import ReservationSummary from '@src/components/ui/ReservationSummary';
import { useLocation } from 'react-router-dom';
import { nl2br } from '@src/util/string';
import dayjs from 'dayjs';

const LockScreen: React.FC = () => {
  const { t } = useTranslation("common", { keyPrefix: "components.LockScreen" });
  const { state } = useLocation();
  const { reservation } = useReservationContext();

  let message = t('greeting');

  if (state?.authCode && reservation) {
    const checkin = reservation.checkinIsToday ?
      t('common:today') :
      dayjs(reservation.checkin).format('ll');

    message = t('found', { checkin })
  }

  return (<>
    <LanguageSwitcher />

    <Container maxWidth="sm" sx={{ my: { xs: 2, sm: 4 } }}>
      <Card variant="outlined" sx={{ p: 3 }}>
          <Header />

          {state?.authCode &&
            <Typography
              paragraph
              variant="body1"
              sx={{
                mt: 4,
                whiteSpace: 'pre-wrap'
              }}>
                {t('found')}
              </Typography>
          }

          <ReservationSummary mt={3} />

          <Form authCode={state?.authCode} />

      </Card>

      <Footer />
    </Container>
  </>);
};

export default LockScreen;

