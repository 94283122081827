import {
  Card,
  CardContent,
  Container,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { GuestBookProvider } from '@src/providers/GuestBookProvider';
import Header from '@src/components/ui/Header';
import ReservationSummary from '@src/components/ui/ReservationSummary';
import Footer from '@src/components/ui/Footer';
import Info from './Info';
import CheckinCode from './CheckinCode';
import MultipleRooms from './MultipleRooms';
import NumberOfGuests from './NumberOfGuests';
import PrimaryGuest from './PrimaryGuest';
import AccompanyingGuests from './AccompanyingGuests';
import LockScreen from '@src/routes/LockScreen';
import LoadingBackdrop from '@src/components/ui/LoadingBackdrop';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@src/util/router';
import './i18n';

const Entrance: React.FC = () => {
  const { t } = useTranslation('Entrance');
  const { isLoading, reservation, guestBooks } = useReservationContext();
  const { index } = useQuery();
  const navigate = useNavigate();

  let guestBook = guestBooks && guestBooks[index || 0];

  if (isLoading) {
    return <LoadingBackdrop />;
  }

  if (reservation && !guestBooks) {
    return <LockScreen />
  }

  const setGuestBook = (i) => {
    navigate(`/${reservation.hashedId}?index=${i}`);
  }

  return (<>
    <Header logo />

    <Container maxWidth="sm">
      <MultipleRooms setGuestBook={setGuestBook} selectedIndex={index} />

      <GuestBookProvider key={guestBook?.hashedId} guestBook={guestBook}>
        <Info sx={{ my: 2 }} />

        <CheckinCode />

        <Card sx={{ my: 2 }}>
          <CardContent sx={{ p: 3 }}>
            <Typography variant="label2Bold" component="div" sx={{ mb: 2 }}>
              {reservation?.numberOfRooms == 1 ? t('guestBook') : t('guestBookIndex', {n: Number(index) + 1})}
            </Typography>
            <NumberOfGuests>
              <PrimaryGuest />
              <AccompanyingGuests />
            </NumberOfGuests>
          </CardContent>
        </Card>
      </GuestBookProvider>

      <Card variant="outlined">
        <CardContent sx={{ p: 3 }}>
          <ReservationSummary />
        </CardContent>
      </Card>

      <Footer />
    </Container>
  </>);
};

export default Entrance;

