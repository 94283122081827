import {
  Alert,
  Card,
  SxProps,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { nl2br } from '@src/util/string';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';

const Info = (props: { sx: SxProps }) => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.Info' });
  const { reservation } = useReservationContext();
  const { guestBook } = useGuestBookContext();

  if (!reservation) { return null; }

  let color;
  switch (guestBook.checkinStatus) {
  case "submitted":
    color = "secondary";
    break;
  default:
    color = "info";
  }

  let checkinTime = reservation.checkinStartTime.format(
    reservation.checkinIsToday ? 'LT' : 'LLL'
  );
  if (reservation.checkinIsThisYear) {
    checkinTime = checkinTime.replace(new RegExp(reservation.checkinStartTime.format('YYYY') + '[年년 ]?'), '');
  }

  let message = t(`${guestBook.checkinStatus}.message`, '', { checkinTime });
  if (!message) { message = t('pre-entry.message'); }

  return (<>
    <Card {...props} sx={{ p: 1, ...props.sx }}>
      <Alert
        icon={false}
        severity="info"
        color={color}
        sx={{
          borderRadius: '8px',
          color: `${color}.main`,
          padding: '8px 16px'
        }}
      >
        {message.split("\n\n").map((s, i, a) => (
          <Typography
            key={i}
            variant="body2"
            paragraph={!!a[i+1]}
            sx={{ fontWeight: 'bold' }}
          >
            {nl2br(s)}
          </Typography>
        ))}
      </Alert>
    </Card>
  </>);
};

export default Info;
