import { Suspense } from "react";
import { Outlet } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import LoadingBackdrop from '@src/components/ui/LoadingBackdrop';
import Fallback from '@src/components/ui/Fallback';

const ApplicationLayout = () => {
  return (
    <Suspense fallback={<LoadingBackdrop />}>
      <ErrorBoundary fallback={(props) => <Fallback {...props} />}>
        <Outlet />
      </ErrorBoundary>
    </Suspense>
  );
};

export default ApplicationLayout;
