import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import React from 'react';
import { Link, To } from 'react-router-dom';

type Props = {
  children: React.ReactNode,
  linkTo: To
}

const AddGuestInfoButton = ({ children, linkTo, ...rest }: Props) => {
  return (
    <Link to={linkTo} style={{ width: '100%' }}>
      <Button
        fullWidth
        startIcon={<AddIcon />}
        sx={{
          fontSize: 16,
          lineHeight: 1.25,
          minHeight: 64,
          borderRadius: 2,
          border: '1px dashed #507763',
          background: '#F0F5F3',
          color: '#507763',
          fontWeight: 'bold',
          '&:hover': {
            background: '#D1E0DA',
          },
        }}
        {...rest}
      >
        {children}
      </Button>
    </Link>
  );
};

export default AddGuestInfoButton;
