import React, { MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Typography, Dialog, DialogContent, DialogContentText, Divider, Stack, Box, Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import CameraPassport from '@src/routes/GuestForm/CameraPassport';
import CameraButton from '../../components/buttons/CameraButton';
import AnnotationBanner from '../../components/ui/AnnotationBanner';
import { useReservationContext } from '@src/providers/ReservationProvider';

const PassportVerify: React.FC = ({ setValue, ready, setIsEditing, setImage }) => {
  const { t } = useTranslation('GuestForm');
  const { reservation, guestBooks } = useReservationContext();
  const navigate = useNavigate();
  const [showCamera, setShowCamera] = useState(false);
  const { guestBookId } = useParams();

  const handleOpen: MouseEventHandler = () => {
    setShowCamera(true);
  };

  const handleClose = () => {
    setShowCamera(false);
  };
  const guestBookIndex = guestBooks.findIndex((g) => g.hashedId == guestBookId );

  return (
    <>
      {/* <AnnotationBanner /> */}

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant='label3Bold'>
          {t('components.CameraPassport.title')}
        </Typography>
        <Typography variant='body2'>
          {t('components.CameraPassport.caption')}
        </Typography>
        <CameraButton onClick={handleOpen}>
          {t('components.CameraPassport.register')}
        </CameraButton>
      </Stack>

      <Divider />

      <Box sx={{ p: 3 }}>
        <Button
          fullWidth
          variant='secondary'
          onClick={() => navigate(`/${reservation.hashedId}?index=${guestBookIndex}`)}
        >
          { t('components.CameraPassport.cancel') }
        </Button>
        {/* <Button
          fullWidth
          variant='secondary'
          onClick={ready
            ? () => navigate(`/${reservation.hashedId}`)
            : () => navigate(`/${reservation.hashedId}`)
          }
          type={ready ? 'button' : 'submit' }
        >
          { t('components.CameraPassport.cancel') }
        </Button> */}
      </Box>

      <Dialog
        fullScreen
        open={showCamera}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ p: 0, background: '#f5f5f5' }}>
          {showCamera &&
            <CameraPassport
              setIsEditing={setIsEditing}
              dialogClose={handleClose}
              setImage={setImage}
            />
          }
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PassportVerify;
