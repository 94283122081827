import React, { useState, useEffect } from "react";
import { Grid, Box, Typography, FormControl, FormLabel,
  Divider, Autocomplete, TextField, MenuItem,
  Dialog, DialogContent,
  Button,
  Stack} from '@mui/material';
import { useTranslation } from 'react-i18next';
import SubmitButton from '@src/components/buttons/SubmitButton';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import countries from "../../countries";
import PhoneNumberInput from '@src/components/ui/PhoneNumberInput';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import { useReservationContext } from '@src/providers/ReservationProvider';
import EmailNotification from "./EmailNotification";
import { useNavigate, Link, useParams } from 'react-router-dom';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import pick from 'lodash/pick';
import MessageBar from "../../components/ui/MessageBar";
import CameraPassport from './CameraPassport';
import Select, { SelectChangeEvent } from '@mui/material/Select';

const PassportEdit: React.FC = ({ image, setImage, setIsEditing }) => {
  const { reservation, guestBooks } = useReservationContext();
  const { guestBook, currentGuest, updateGuestBook } = useGuestBookContext();
  const { t } = useTranslation('GuestForm', { keyPrefix: 'components.PassportForm' });
  const guest = currentGuest();
  const primary = guest.role === 'primary';
  const methods = useForm({
    defaultValues: guest,
    shouldUnregister: false
  });

  const navigate = useNavigate();
  const { control, handleSubmit, setValue, formState: { errors, isSubmitting }, watch, setFocus, clearErrors } = methods;
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [phoneNumberInputKey, setPhoneNumberInputKey] = useState(1);
  const [guestForAddressCopy, setGuestForAddressCopy] = useState('NULL');
  const [guestForPhoneCopy, setGuestForPhoneCopy] = useState('NULL');
  const registeredGuests = guestBook.guests.filter(g => (g.passportStatus === 'registered' && g !== guest));
  const [showMessage, setShowMessage] = useState(false);
  const [country, countryCode, address, phoneNumber, phoneNumberCountryCode, japaneseNationalOrResident] = watch([
    'country', 'countryCode', 'address', 'phoneNumber', 'phoneNumberCountryCode', 'japaneseNationalOrResident'
  ]);
  const [openCamera, setOpenCamera] = useState(false);
  const { guestIndex } = useParams();
  const attributes = (f) => {
    return pick(
      f,
      'countryCode',
      'address',
      'phoneNumber',
      'phoneNumberCountryCode',
      'preference',
      'email',
      'japaneseNationalOrResident'
    )
  }
  const guestBookIndex = guestBooks.findIndex((g) => g.hashedId == guestBook.hashedId );

  useEffect(() => {
    if (guest?.photo?.base64) { setImage(`data:image/jpeg;base64,${guest.photo.base64}`) }

    if (guest.countryCode) {
      setValue('country', countries.find(c => c.code.toLowerCase() === guest.countryCode));
      setValue('countryCode', guest.countryCode.toLowerCase());
    } else if (guest.photo?.info?.countryCode) {
      setValue('country', countries.find(c => c.code.toLowerCase() === guest.photo.info?.countryCode));
      setValue('countryCode', guest.photo.info?.countryCode);
    } else {
      setValue('country', null);
    }

    if (guest.phoneNumberCountryCode) {
      setValue('phoneNumberCountryCode', guest.phoneNumberCountryCode);
    } else if (guest.photo?.info?.countryCode) {
      setValue('phoneNumberCountryCode', guest.photo.info?.countryCode);
    }
    setValue('japaneseNationalOrResident', false);
  }, []);

  const handlePhoneNumberCountryChange = (code) => {
    if (code.toLowerCase() === phoneNumberCountryCode.toLowerCase()) {
      return;
    }
    setValue('phoneNumberCountryCode', code.toLowerCase());
    setValue('phoneNumber', null);
    setPhoneNumberInputKey((current) => current + 1);
    setFocus('phoneNumber');

    if (countryCode == 'NULL') {
      setValue('countryCode', code.toLowerCase());
    }
  };

  const handlePhoneCopy = (e: SelectChangeEvent) => {
    setGuestForPhoneCopy(e.target.value)
    const selected = registeredGuests.filter(g => g.hashedId === e.target.value)

    setValue('phoneNumberCountryCode', selected[0]?.phoneNumberCountryCode)
    setValue('phoneNumber', selected[0]?.phoneNumber)
    clearErrors('phoneNumber');
    setPhoneNumberInputKey((current) => current + 1);
  };

  const handleCloseCamera = () => {
    setOpenCamera(false);
  };

  const handleOpenCamera = () => {
    setOpenCamera(true);
  };

  const onSubmit = (form) => {
    let params = attributes(form)
    params.hashedId = guest.hashedId

    const guests = [params];
    guestBook.guests.filter((g) => g.hashedId != params.hashedId).map(
      (g) => {
        let guestParams = attributes(g)
        guestParams.hashedId = g.hashedId
        guests.push(guestParams)
      }
    );

    return updateGuestBook({ numberOfGuests: guestBook.numberOfGuests, guests }).then(() => {
      navigate(`/${reservation.hashedId}?index=${guestBookIndex}`);
    }).catch((err) => {
      console.log(err);
      setShowMessage(t('error.updateGuestFailed'));
    });
  };

  return (<>
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="label3Bold">{t('title')}</Typography>

          {image &&
            <Box src={image} component="img" display="block" sx={{ maxWidth: 320, width: '100%' }}/>
          }

          <Stack spacing={0.5}>
            <Stack direction="row" spacing={1}>
              <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>PASSPORT No</Typography>
              <Typography variant="label3">{ guest.photo?.info?.documentNumber || "--------------" }</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>NAME</Typography>
              <Typography variant="label3">{ guest.photo?.info?.name || "---- ----" }</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>NATIONALITY</Typography>
              <Typography variant="label3">{ countries.find(c => c.code === guest.photo?.info?.countryCode)?.label || "-----" }</Typography>
            </Stack>
            <Stack direction="row" spacing={1}>
              <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>DATE OF EXPIRY</Typography>
              <Typography variant="label3">{ guest.photo?.info?.expiryDate || "-- --- ----" }</Typography>
            </Stack>
          </Stack>
          <Button
            variant="bordered"
            startIcon={<CameraAltIcon />}
            sx={{ width: 'fit-content' }}
            onClick={handleOpenCamera}
          >
            {t('updatePhoto')}
          </Button>
        </Stack>

        <Divider/>

        <Stack spacing={2} sx={{ p: 3 }}>
          <Grid item xs={12}>
            <Typography variant="label3Bold">
              {primary ? t('primaryGuestInfo') : t('accompanyGuestInfo', { index: Number(guestIndex) })}
            </Typography>

            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }} error={!!errors.country}>
              <FormLabel>{t('address')}</FormLabel>
              <Controller
                name='country'
                control={control}
                rules={{ required: t('validation.countryRequired') }}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    id="country-select"
                    fullWidth
                    options={countries}
                    autoHighlight
                    getOptionLabel={(option) => option.label || ''}
                    isOptionEqualToValue={(option, value) => option.code === value.code}
                    defaultValue={countries.find(c => c.code.toLowerCase() === guest.countryCode) || {}}
                    onChange={(e, value) => {
                      field.onChange(value);
                      setValue('countryCode', value.code.toLowerCase());
                      setValue('country', value);
                    }}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>{option.label} ({option.code})</Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{ ...params.inputProps }}
                        error={!!errors.country}
                        helperText={errors.country ? errors.country.message : ''}
                      />
                    )}
                    sx={{
                      '.MuiOutlinedInput-root': {
                        p: 0,
                        '.MuiAutocomplete-input': {
                          p: 0,
                          px: 2
                        }
                      }
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl variant="outlined" fullWidth sx={{ mt: 1 }} error={!!errors.address}>
              <Controller
                name='address'
                control={control}
                rules={{ required: t('validation.addressRequired') }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    variant="outlined"
                    error={!!errors.address}
                    helperText={errors.address ? errors.address.message : ''}
                  />
                )}
              />
            </FormControl>

            <Divider sx={{ borderStyle: "dashed", mt: 2 }} />

            <FormControl variant="outlined" fullWidth sx={{ mt: 2, display: 'block' }}>
              <Select
                name='guestForAddressCopy'
                value={guestForAddressCopy}
                onChange={(e) => {
                  setGuestForAddressCopy(e.target.value)
                  const selected = registeredGuests.filter(g => g.hashedId === e.target.value)
                  setValue('countryCode', selected[0].countryCode)
                  setValue('country', countries.find(c => c.code.toLowerCase() === selected[0].countryCode))
                  setValue('address', selected[0].address)
                }}
                sx={{ width: '80%' }}
              >
                <MenuItem value={'NULL'}>{t('guestSelectBox')}</MenuItem>
                {registeredGuests.map((guest) => (
                  <MenuItem value={guest.hashedId} key={guest.hashedId}>{guest.name}</MenuItem>
                ))}
              </Select>
              <FormLabel sx={{
                width: '20%',
                textAlign: 'right',
                display: 'inline-block'
              }}>
                {t('sameAs')
              }</FormLabel>
            </FormControl>

            <FormControl variant="outlined" fullWidth sx={{ mt: 2 }} error={!!errors.phoneNumber}>
              <FormLabel>{t('phoneNumber')}</FormLabel>
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <PhoneNumberInput
                    key={phoneNumberInputKey}
                    initialValue={guest.phoneNumber}
                    onChangeNumber={(value) => setValue('phoneNumber', value)}
                    onChangeCountry={handlePhoneNumberCountryChange}
                    onChangeValidity={(valid) => setPhoneNumberValid(valid)}
                    initOptions={{
                      initialCountry: guest.initialPhoneNumberCountryCode || t('guest.phoneNumber.defaultCountry'),
                      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js",
                    }}
                    error={!!errors.phoneNumber}
                    helperText={errors.phoneNumber ? errors.phoneNumber.message : ''}
                    {...field}
                  />
                )}
                defaultValue={guest.phoneNumber || null}
                rules={{
                  required: t('validation.phoneNumberRequired'),
                  validate: {
                    invalid: () => phoneNumberValid || t('error.invalidPhoneNumber'),
                  }
                }}
              />
            </FormControl>

            <Divider sx={{ borderStyle: "dashed", mt: 2 }} />

            <FormControl variant="outlined" fullWidth sx={{ mt: 2, display: 'block' }}>
              <Select
                name='guestForPhoneCopy'
                value={guestForPhoneCopy}
                onChange={handlePhoneCopy}
                sx={{ width: '80%' }}
              >
                <MenuItem value={'NULL'}>{t('guestSelectBox')}</MenuItem>
                {registeredGuests.map((g) => (
                  <MenuItem value={g.hashedId} key={g.hashedId}>{g.name}</MenuItem>
                ))}
              </Select>
              <FormLabel sx={{
                width: '20%',
                textAlign: 'right',
                display: 'inline-block'
              }}>
                {t('sameAs')
              }</FormLabel>
            </FormControl>
          </Grid>

          { primary && <EmailNotification /> }
        </Stack>

        <Divider/>

        <Stack spacing={2} direction='row' sx={{ p: 3}}>
          <Button
            variant="secondary"
            component={Link}
            to={`/${reservation.hashedId}?index=${guestBookIndex}`} tabIndex={-1}
            sx={{ flex: 1 }}
          >
            { t('cancel') }
          </Button>
          <SubmitButton loading={isSubmitting} sx={{ flex: 1 }}>{ t('save') }</SubmitButton>
        </Stack>

        { showMessage && <MessageBar message={showMessage} severity='error' />}
      </Box>
    </FormProvider>

    <Dialog
      fullScreen
      open={openCamera}
      onClose={handleCloseCamera}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ p: 0, background: '#f5f5f5' }}>
        {openCamera &&
          <CameraPassport
            dialogClose={handleCloseCamera}
            setImage={setImage}
            setIsEditing={setIsEditing}
          />
        }
      </DialogContent>
    </Dialog>
  </>);
};

export default PassportEdit;
