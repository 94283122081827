import { FormHelperText } from "@mui/material";
import CustomIcon from "./CustomIcon";


const FormErrorMessage = ({
  message
}: {
  message?: string;
}) => {
  return (
    <FormHelperText className="Mui-error">
      <CustomIcon variant="warning" color='error' sx={{ mr: 0.5 }}/>
      {message}
    </FormHelperText>
  );
}

export default FormErrorMessage;
