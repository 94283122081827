export default {
  "title": {
    "primary": "主要宾客",
    "companion": "同行者_{{count}}",
  },
  "section1": {
    "title": {
      "primary": "主要宾客是否是日本国籍或在日本有地址？",
      "companion": "登记对象是否是日本国籍或在日本有地址？",
    },
    "caption": "如果您不是日本国籍且在日本没有地址，需要注册护照。",
  },
  "section2": {
    "title": {
      "primary": "主要宾客个人信息",
      "companion": "同行者_{{count}} 的个人信息",
    },
  },
  "section3": {
    "title": "办理入住完成通知邮件设置",
    "description": "在完成办理入住时，您可以通过电子邮件接收有关如何进入房间（房间进入PIN码、房间号码）的信息。",
    "caption": "虽然这些信息可以在办理入住终端上临时显示，但如果您忘记了，通过电子邮件接收将是令人放心的。此设置仅适用于主要宾客。",
  },
  "guest": {
    "name": {
      "label": "姓名（必填）",
      "placeholder": "乐天 太郎",
      "errors": {
        "required": "请输入姓名",
      },
    },
    "address": {
      "label": "地址（必填）",
      "placeholder": "东京都世田谷区玉川1-14-1",
      "errors": {
        "required": "请输入地址",
      },
    },
    "phoneNumber": {
      "label": "联系电话号码（必填）",
      "defaultCountry": "cn",
      "errors": {
        "required": "请输入联系电话号码",
        "invalid": "请输入有效的电话号码",
      },
    },
    "checkinNotification": {
      "label": "您是否希望接收邮件？（必填）",
      "errors": {
        "required": "请选择",
      },
    },
    "email": {
      "label": "电子邮件地址（必填）",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "请输入电子邮件地址",
        "invalid": "请输入有效的电子邮件地址",
      },
    },
  },
  "guest_type_menu": {
    "caption": "請選擇適合此住宿者的選項。",
    "japanese_title": "日本人及在日本有住所的人",
    "japanese_caption": "包括居住在海外的日本人和居住在日本的外國人",
    "foreigner_title": "不住在日本的外國人",
    "foreigner_caption": "外國旅客等"
  },
  "components": {
    "CameraSelfie": {
      "register": "面部註冊",
      "register_photo": "註冊面部信息",
      "update": "重新註冊",
      "submitted": "面部圖像已註冊",
      "failed": "無法獲取面部圖像的註冊狀況。請重新加載頁面。",
      "instruction_1": "给{{name}}的脸拍张照。",
      "instruction_2": "請確認面部未被口罩或帽子遮擋。",
      "cancel": "取消",
      "capture": "拍攝",
      "captureAgain": "重新拍攝",
      "useThisPhoto": "使用這張照片",
      "captureNoteSelfie": "請確認照片中沒有其他人的面部，且面部未被口罩或帽子遮擋。",
      "upload_success": "上傳完成"
    },
    "CameraPassport": {
      "title": "護照資訊",
      "caption": "請拍攝護照照片，並從圖片中掃描護照資訊。請準備好您的護照。",
      "register": "註冊護照",
      "update": "變更",
      "instruction": "請拍攝護照上有照片的頁面，使其位於框內。",
      "captureNotePassport": "請確認照片和文字清晰後，掃描護照資訊。",
      "scan": "掃描",
      "scanSuccess": "護照掃描完成。請確認掃描內容無誤。",
      "registerContent": "以此內容註冊",
      "cancel": "取消",
      "capture": "拍攝",
      "captureAgain": "重新拍攝",
      "scanFailed": "護照掃描失敗。",
      "scanFailedInstruction": "請重新拍攝，若多次失敗，請手動註冊護照資訊。",
      "takeAnotherPhoto": "重新拍攝",
      "addManually": "手動輸入護照資訊",
      "error": {
        "failedScan": "掃描護照失敗。"
      }
    },
    "PassportForm": {
      "title": "護照資訊",
      "number": "護照號碼（必填）",
      "name": "姓名（必填）",
      "country": "國籍（必填）",
      "countrySelect": "選擇國家",
      "expiryDate": "有效期限（必填）",
      "caption": "請按照拍攝的護照上的資訊填寫。",
      "save": "保存",
      "year": "年",
      "month": "月",
      "day": "日",
      "validation": {
        "numberRequired": "請輸入護照號碼",
        "nameRequired": "請輸入姓名",
        "countryRequired": "請選擇國籍",
        "expiryDateRequired": "請選擇有效期限",
        "yearRequired": "請選擇年份",
        "monthRequired": "請選擇月份",
        "dayRequired": "請選擇日期",
      },
      "primaryGuestInfo": "主要宾客信息",
      "accompanyGuestInfo": "同行者_{{index}}信息",
      "updatePhoto": "更改照片",
      "address": "地址（必填）",
      "sameAs": "复制",
      "phoneNumber": "电话号码（必填）",
      "guestSelectBox": "选择其他宾客",
      "cancel": "取消",
      "error": {
        "updateGuestFailed": "更新宾客信息失败。请联系主办方获取更多信息。",
        "invalidPhoneNumber": "请输入有效的电话号码",
      }
    }
  },
  "sameWithPrimaryGuest": "与主要宾客相同",
  "register": "注册",
};
