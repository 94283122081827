export default {
  "onlineCheckIn": "オンラインチェックイン",
  "guestBook": "宿泊者一覧",
  "nthGuestBook": "宿泊者一覧（{{n}}部屋目）",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "チェックインまでに全ての宿泊者情報の登録を完了してください。",
      },
      "submitted": {
        "message": "チェックインの準備ができました。\nチェックイン受付開始時間は{{checkinTime}}からです。",
      },
      "checked-in": {
        "message": "この予約はチェックイン済みです。\n\n部屋番号およびルームキーをお忘れの場合は、この画面に表示されている二次元コードまたはチェックイン番号を使って、チェックイン端末上でそれらの再表示操作をしてください。",
      },
      "checked-out": {
        "message": "この度は、Rakuten STAYにご宿泊いただき、誠にありがとうございます。\n\nまたのご利用をお待ちしております。",
      },
    },
    "NumberOfGuests": {
      "total": "宿泊人数: {{count}}人",
      "registered": "<0>登録済み: <1>{{count}}人</1></0>",
      "description": "宿泊人数を登録してください。",
      "caption": "登録した宿泊人数全員分の宿泊者名簿情報をご登録いただきます。",
      "edit": "変更",
      "cancel" : "キャンセル",
      "update" : "決定",
      "numberOfGuestsWithCount": "{{count}}名",
      "warning" : {
        "title": "ご注意ください",
        "description": "宿泊人数の増減による料金の変動はありません。\nまた、ご注文済みのお食事がある場合にもその数量は変更されませんのでご了承ください。\n 人数を減らす場合、すでに登録済みの情報がある場合にはその情報は破棄されます。",
      }
    },
    "PrimaryGuest": {
      "title": "代表者",
      "register": "登録する",
      "update": "編集",
    },
    "CheckinCode": {
      "description": "チェックイン端末（SMART CHECK-IN）でこのQRコードまたは、チェックイン番号を使用してチェックイン手続きをしてください。",
      "label": "チェックイン番号",
      "ciQrCode": "チェックインQR",
      "photo": {
        "instruction_1": "チェックイン当日、現地での本人確認に顔認証がご利用いただけます。",
        "instruction_2": "代表者の顔情報を登録して、オンラインチェックインを完了しましょう。",
        "submitted": "顔画像登録済み",
      },
    },
    "AccompanyingGuests": {
      "title_zero": "同行者",
      "title": "同行者_{{count}}",
      "register": "登録する",
      "update": "編集",
    },
    "MultipleRooms": {
      "label": "複数のお部屋が予約されています。",
      "description": "お部屋ごとのチェックインとなりますので、それぞれのお部屋ごとにオンラインチェックインをお願いします。",
      "ongoing": "未完了",
      "complete": "完了",
      "selectedRoom": "選択中の部屋",
      "roomIndex": "{{n}}部屋目",
    },
  },
};
