import React from 'react';
import {
  Box,
  Link,
  Typography,
} from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useTranslation } from 'react-i18next';

const Footer: React.FC = () => {
  const { t } = useTranslation("common", { keyPrefix: "components.Footer" });

  return (
    <Box sx={{ my: 4, textAlign: 'center' }} component="footer">
      <Typography variant="body1" gutterBottom>
        <Link href={t('privacyPolicy.href')} target="_blank" color="info.main">
          {t('privacyPolicy.label')}
          <LaunchIcon sx={{ ml: '2px', verticalAlign: 'middle', fontSize: 'body1.fontSize' }} />
        </Link>
      </Typography>
      <Typography variant="body1" gutterBottom>
        <Link href={t('agreement.href')} target="_blank" color="info.main">
          {t('agreement.label')}
          <LaunchIcon sx={{ ml: '2px', verticalAlign: 'middle', fontSize: 'body1.fontSize' }} />
        </Link>
      </Typography>
    </Box>
  );
};

export default Footer;

