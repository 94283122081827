import React from 'react';
import { createTheme, CSSObject } from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { alpha, darken } from '@mui/material/styles';
import { isTouchDevice } from '@src/util/device';
import { borderColor, minHeight } from '@mui/system';

const buttonStyle: {
  base: CSSObject
  primary: CSSObject
  secondary: CSSObject
  bordered: CSSObject
  dangerBordered: CSSObject,
  white: CSSObject,
} = {
  base: {
    '.MuiButton-startIcon': {
      margin: '0 4px 0 0'
    },
    '.MuiButton-endIcon': {
      margin: '0 0 0 4px'
    },
    lineHeight: 1.25,
    textTransform: 'none',
    padding: '12px 16px',
  },
  primary: {
    color: 'white',
    fontWeight: 'bold',
    background: 'linear-gradient(90deg, #527A66 0%, #334D40 100%)',
    position: 'relative',
    zIndex: 0,
    overflow: 'hidden',
    '&::before': {
      content: '""',
      position: 'absolute',
      inset: 0,
      zIndex: -1,
      background: 'linear-gradient(90deg, #3E5C4D 0%, #293D33 100%)',
      opacity: 0,
      transition: 'opacity 300ms',
    },
    '&:hover': {
      '&::before': {
        opacity: 1,
      },
    }
  },
  secondary: {
    color: 'black',
    background: '#f5f5f5',
    border: '1px solid #ddd',
    transition: 'background 300ms; border 300ms',
    '&:hover': {
      background: '#eee',
      border: '1px solid #bbb'
    }
  },
  bordered: {
    color: '#507763',
    fontWeight: 'bold',
    background: 'transparent',
    border: '1px solid #507763',
    transition: 'background 300ms; border 300ms',
    '&:hover': {
      background: '#F0F5F3',
      border: '1px solid #507763'
    }
  },
  dangerBordered: {
    color: '#bf0000',
    fontWeight: 'bold',
    background: 'transparent',
    border: '1px solid #bf0000',
    transition: 'background 300ms; border 300ms',
    '&:hover': {
      background: '#FCF2F2',
      border: '1px solid #bf0000'
    }
  },
  white: {
    color: '#507763',
    fontWeight: 'bold',
    background: 'white',
    border: 0,
    transition: 'background 300ms; border 300ms',
    '&:hover': {
      background: '#F0F5F3',
    }
  }
}

const defaultShadows = createTheme({}).shadows;

const themeColors = {
  primary: '#507763',
  secondary: '#507763',
  success: '#57CA22',
  warning: '#FFA319',
  error: '#DF0101',
  info: '#5A7086',
  black: '#000',
  white: '#fff',
  primaryAlt: '#090A0C',
  trueWhite: '#ffffff',
  trueBlack: '#000000',
};

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      tablet: 1024,
      lg: 1200,
      xl: 1536,
    },
  },
  typography: {
    fontFamily: [
      '"Noto Sans JP"',
      '"Helvetica Neue"',
      '"Helvetica"',
      '"Hiragino Sans"',
      '"Hiragino Kaku Gothic ProN"',
      '"Arial"',
      '"Yu Gothic"',
      '"Meiryo"',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: "1.6rem",
    },
    h2: {
      fontSize: "1.3rem",
    },
    h3: {
      fontSize: "1.2rem",
    },
    h4: {
      fontSize: "1rem",
    },
    h5: {
      fontSize: "0.875rem",
    },
    h6: {
      fontSize: "0.75rem",
    },
    label2: {
      fontSize: 20,
      lineHeight: 1.25,
    },
    label2Bold: {
      fontSize: 20,
      lineHeight: 1.25,
      fontWeight: 'bold'
    },
    label3: {
      fontSize: 16,
      lineHeight: 1.25,
    },
    label3Bold: {
      fontSize: 16,
      lineHeight: 1.25,
      fontWeight: 'bold'
    },
    label4: {
      fontSize: 14,
      lineHeight: 1.25,
    },
    label4Bold: {
      fontSize: 14,
      lineHeight: 1.25,
      fontWeight: 'bold'
    },
    label5: {
      fontSize: 12,
      lineHeight: 1.25,
    },
    label5Bold: {
      fontSize: 12,
      lineHeight: 1.25,
      fontWeight: 'bold'
    },
  },
  shadows: defaultShadows.map(s => s.replace(/0,0,0,/g, '34,51,84,').replace(/0.2/, '0.1').replace(/0.14/, '0.04').replace(/0.12/, '0.02')),
  palette: {
    primary: {
      main: alpha(themeColors.trueBlack, 0.8),
      light: alpha(themeColors.trueBlack, 0.6),
      dark: alpha(themeColors.trueBlack, 0.9),
      contrastText: '#fff'
    },
    brand: {
      main: '#00B900',
      light: '#2AC72A',
      dark: '#009500',
      contrastText: '#fff'
    },
    rakuten: {
      main: '#BF0000',
      light: '#E61717',
      dark: '#930A0A',
      contrastText: '#fff',
      gradient: `linear-gradient(90deg, #BF0000 0%, #630000 100%)`,
    },
    secondary: {
      bg: alpha('#669980', 0.1),
      light: alpha(themeColors.secondary, 0.75),
      main: themeColors.secondary,
      dark: darken(themeColors.secondary, 0.2),
      gradient: `linear-gradient(90deg, ${themeColors.secondary} 0%, ${darken(themeColors.secondary, 0.2)} 100%)`,
    },
    // primary: {
    //   bg: alpha(themeColors.primary, 0.1),
    //   light: alpha(themeColors.primary, 0.75),
    //   main: themeColors.primary,
    //   dark: darken(themeColors.primary, 0.2)
    // },
    success: {
      bg: alpha(themeColors.success, 0.1),
      light: alpha(themeColors.success, 0.75),
      main: themeColors.success,
      dark: darken(themeColors.success, 0.2)
    },
    warning: {
      bg: alpha(themeColors.warning, 0.1),
      light: alpha(themeColors.warning, 0.75),
      main: themeColors.warning,
      dark: darken(themeColors.warning, 0.2)
    },
    error: {
      bg: alpha(themeColors.error, 0.1),
      light: alpha(themeColors.error, 0.75),
      main: themeColors.error,
      dark: darken(themeColors.error, 0.2)
    },
    info: {
      bg: alpha(themeColors.info, 0.1),
      light: alpha(themeColors.info, 0.75),
      main: themeColors.info,
      dark: darken(themeColors.info, 0.2)
    },
    white: {
      main: alpha(themeColors.trueWhite, 0.95),
      light: themeColors.trueWhite,
      dark: alpha(themeColors.white, 0.02),
      contrastText: themeColors.black,
      alpha: {
        5: alpha(themeColors.trueWhite, 0.02),
        10: alpha(themeColors.trueWhite, 0.1),
        20: alpha(themeColors.trueWhite, 0.2),
        30: alpha(themeColors.trueWhite, 0.3),
        50: alpha(themeColors.trueWhite, 0.5),
        70: alpha(themeColors.trueWhite, 0.7),
        80: alpha(themeColors.trueWhite, 0.8),
        90: alpha(themeColors.trueWhite, 0.9),
        100: themeColors.trueWhite,
      },
    },
    dark: {
      main: '#333',
      dark: alpha(themeColors.trueBlack, 0.9),
      contrastText: themeColors.trueWhite,
    },
    darkGrey: {
      main: grey[600],
      dark: grey[800],
    },
    grey: {
      main: grey[300],
      dark: grey[400],
      contrastText: grey[700],
      alpha: {
        5: alpha(themeColors.trueBlack, 0.02),
        10: alpha(themeColors.trueBlack, 0.1),
        20: alpha(themeColors.trueBlack, 0.2),
        30: alpha(themeColors.trueBlack, 0.3),
        50: alpha(themeColors.trueBlack, 0.5),
        70: alpha(themeColors.trueBlack, 0.7),
        80: alpha(themeColors.trueBlack, 0.8),
        90: alpha(themeColors.trueBlack, 0.9),
        100: themeColors.trueBlack,
      }
    },
    background: {
      default: '#f5f5f5',
      light: '#fbfbf9',
      grey: '#666',
      dark: '#11192a',
      selected: '#EAF1ED',
      annotation: '#FDF9E2',
    },
    text: {
      primary: '#000',
      secondary: '#555',
    },
  },
  components: {
    MuiInputLabel: {
      defaultProps: {
        shrink: true
      },
      styleOverrides: {
        root: {
          color: 'black',
          fontSize: 14,
          lineHeight: 1.25,
          transform: 'none',
          position: 'unset',
          marginBottom: 8,
          '&.Mui-focused': {
            color: 'black',
            '&.Mui-error': {
              color: '#DF0101'
            }
          }
        }
      }
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          fontSize: 12,
          lineHeight: 1.25,
          margin: '4px 0 0 0',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: 16,
          lineHeight: 1,
          borderRadius: 6,
        },
        input: {
          height: 'auto',
          background: '#fafafa',
          '&::placeholder': {
            color: '#737373',
            opacity: 1
          }
        }
      },
      variants: [
        {
          props: { size: 'medium' },
          style: {
            input: {
              minHeight: 48,
              padding: '0 16px'
            }
          }
        }
      ]
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          padding: '6px 0',
        }
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: !isTouchDevice(),
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        size: 'medium',
        variant: 'base',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          ...buttonStyle.base
        },
      },
      variants: [
        {
          props: { variant: 'base' },
          style: {
            ...buttonStyle.base
          }
        },
        {
          props: { variant: 'primary', size: 'medium' },
          style: {
            fontSize: '16px',
            minHeight: 48,
            borderRadius: 96,
            ...buttonStyle.primary,
          }
        },
        {
          props: { variant: 'primary', size: 'small' },
          style: {
            fontSize: '14px',
            minHeight: 40,
            borderRadius: 80,
            ...buttonStyle.primary,
          }
        },
        {
          props: { variant: 'secondary', size: 'medium' },
          style: {
            fontSize: '16px',
            minHeight: 48,
            borderRadius: 96,
            ...buttonStyle.secondary,
          }
        },
        {
          props: { variant: 'secondary', size: 'small' },
          style: {
            fontSize: '14px',
            minHeight: 40,
            borderRadius: 80,
            ...buttonStyle.secondary,
          }
        },
        {
          props: { variant: 'bordered', size: 'medium' },
          style: {
            fontSize: '16px',
            minHeight: 48,
            borderRadius: 96,
            ...buttonStyle.bordered,
          }
        },
        {
          props: { variant: 'bordered', size: 'small' },
          style: {
            fontSize: '14px',
            minHeight: 40,
            borderRadius: 80,
            ...buttonStyle.bordered,
          }
        },
        {
          props: { variant: 'dangerBordered', size: 'medium' },
          style: {
            fontSize: '16px',
            minHeight: 48,
            borderRadius: 96,
            ...buttonStyle.dangerBordered,
          }
        },
        {
          props: { variant: 'dangerBordered', size: 'small' },
          style: {
            fontSize: '14px',
            minHeight: 40,
            borderRadius: 80,
            ...buttonStyle.dangerBordered,
          }
        },
        {
          props: { variant: 'white', size: 'medium' },
          style: {
            fontSize: '16px',
            minHeight: 48,
            borderRadius: 96,
            ...buttonStyle.white,
          }
        },
        {
          props: { variant: 'white', size: 'small' },
          style: {
            fontSize: '14px',
            minHeight: 40,
            borderRadius: 80,
            ...buttonStyle.white,
          }
        },
      ]
    },
    MuiCard: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        root: {
          borderRadius: 16,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '0.88rem',
          marginBottom: '4px',
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          label2: 'span',
          label2Bold: 'span',
          label3: 'span',
          label3Bold: 'span',
          label4: 'span',
          label4Bold: 'span',
          label5: 'span',
          label5Bold: 'span',
        },
      },
    },
  },
});

declare module '@mui/material/styles' {
  interface Palette {
    dark: Palette['primary'];
  }

  interface PaletteOptions {
    dark?: PaletteOptions['primary'];
  }

  interface Theme {
    status: {
      danger: string;
    }
  }

  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    }
  }

  interface TypographyVariants {
    label2: React.CSSProperties;
    label2Bold: React.CSSProperties;
    label3: React.CSSProperties;
    label3Bold: React.CSSProperties;
    label4: React.CSSProperties;
    label4Bold: React.CSSProperties;
    label5: React.CSSProperties;
    label5Bold: React.CSSProperties;
  }

  // allow configuration using `createTheme()`
  interface TypographyVariantsOptions {
    label2?: React.CSSProperties;
    label2Bold?: React.CSSProperties;
    label3?: React.CSSProperties;
    label3Bold?: React.CSSProperties;
    label4?: React.CSSProperties;
    label4Bold?: React.CSSProperties;
    label5?: React.CSSProperties;
    label5Bold?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    label2: true;
    label2Bold: true;
    label3: true;
    label3Bold: true;
    label4: true;
    label4Bold: true;
    label5: true;
    label5Bold: true;
  }
}

declare module '@mui/material/AppBar' {
  interface AppBarPropsColorOverrides {
    dark: true;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    base: true;
    primary: true;
    secondary: true;
    bordered: true;
    dangerBordered: true;
    white: true;
  }
}

export default theme;
