export default {
  "title": {
    "primary": "Primary Guest",
    "companion": "Companion_{{count}}",
  },
  "section1": {
    "title": {
      "primary": "Is the primary guest a Japanese national or does he/she have an address in Japan?",
      "companion": "Is the registered person a Japanese national or does he/she have an address in Japan?",
    },
    "caption": "If you are not a Japanese national and do not have an address in Japan, passport registration is required.",
  },
  "section2": {
    "title": {
      "primary": "Primary Guest's Personal Information",
      "companion": "Companion_{{count}} Personal Information",
    },
  },
  "section3": {
    "title": "Check-in Completion Notification Email Settings",
    "description": "You can receive information on how to enter your room (room entry PIN, room number) by email when check-in is complete.",
    "caption": "While this information can be temporarily displayed on the check-in terminal, it is reassuring to receive it by email in case you forget. This setting is available only for the primary guest.",
  },
  "guest": {
    "name": {
      "label": "Name (Required)",
      "placeholder": "Rakuten Taro",
      "errors": {
        "required": "Please enter your name",
      },
    },
    "address": {
      "label": "Address (Required)",
      "placeholder": "1-14-1 Tamagawa, Setagaya-ku, Tokyo",
      "errors": {
        "required": "Please enter your address",
      },
    },
    "phoneNumber": {
      "label": "Contact Phone Number (Required)",
      "defaultCountry": "us",
      "errors": {
        "required": "Please enter your contact phone number",
        "invalid": "Please enter a valid phone number",
      },
    },
    "checkinNotification": {
      "label": "Do you want to receive emails? (Required)",
      "errors": {
        "required": "Please make a selection",
      },
    },
    "email": {
      "label": "Email Address (Required)",
      "placeholder": "example@stay.rakuten.com",
      "errors": {
        "required": "Please enter your email address",
        "invalid": "Please enter a valid email address",
      },
    },
  },
  "guest_type_menu": {
    "caption": "Please select the option that applies to this guest.",
    "japanese_title": "Japanese nationals and people with an address in Japan",
    "japanese_caption": "Includes Japanese nationals living overseas and foreign nationals living in Japan.",
    "foreigner_title": "Foreigners not living in Japan",
    "foreigner_caption": "Foreign tourists, etc."
  },
  "components": {
    "CameraSelfie": {
      "register": "Register Face",
      "register_photo": "Register Face",
      "update": "Re-register",
      "submitted": "Facial photo registered",
      "failed": "Failed to retrieve facial photo registration status. Please reload the page.",
      "instruction_1": "Take a photo of {{name}}'s face.",
      "instruction_2": "Make sure that no masks or hats are covering your face.",
      "cancel": "Cancel",
      "capture": "Capture",
      "captureAgain": "Re-Take",
      "useThisPhoto": "Use this photo",
      "captureNoteSelfie": "Please make sure that no one other than yourself is in the photo and that your face is not covered by a mask, hat, etc.",
      "upload_success": "Uploaded"
    },
    "CameraPassport": {
      "title": "Passport Information",
      "caption": "Please take a photo of your passport and scan the passport information from the image. Please have your passport ready.",
      "register": "Register your passport",
      "update": "Change",
      "instruction": "Please make sure that the passport photo page fits within the frame.",
      "captureNotePassport": "Please make sure your photo and text are clearly visible and then scan your passport information.",
      "scan": "Scan",
      "scanSuccess": "Your passport has been scanned. Please check that the scan is correct.",
      "registerContent": "Register this content",
      "cancel": "Cancel",
      "capture": "Capture",
      "captureAgain": "Capture again",
      "scanFailed": "Passport scanning has failed.",
      "scanFailedInstruction": "Please take another photo or, if it continues to fail, please register your passport information manually.",
      "takeAnotherPhoto": "Take another photo",
      "addManually": "Enter your passport information",
      "error": {
        "failedScan": "Failed to scan passport."
      }
    },
    "PassportForm": {
      "title": "Passport Information",
      "number": "Passport number (required)",
      "name": "Name (required)",
      "country": "Nationality (required)",
      "countrySelect": "Select a country",
      "expiryDate": "Expiration date (required)",
      "caption": "Please enter your details exactly as they appear on your passport.",
      "save": "Save",
      "year": "Year",
      "month": "Month",
      "day": "Day",
      "validation": {
        "numberRequired": "Please enter your passport number",
        "nameRequired": "Please enter your name",
        "countryRequired": "Please select a country",
        "expiryDateRequired": "Please enter the expiration date",
        "yearRequired": "Please enter the year",
        "monthRequired": "Please enter the month",
        "dayRequired": "Please enter the day",
        "addressRequired": "Please enter your address",
        "phoneNumberRequired": "Please enter your phone number",
      },
      "primaryGuestInfo": "Representative's personal information",
      "accompanyGuestInfo": "Personal information of companion_{{index}}",
      "updatePhoto": "Update photo",
      "address": "Address (required)",
      "sameAs": "Copy",
      "phoneNumber": "Phone number (required)",
      "guestSelectBox": "Select other guests",
      "cancel": "Cancel",
      "error": {
        "updateGuestFailed": "Failed to update guest information. Please contact the host for more information.",
        "invalidPhoneNumber": "Please enter a valid phone number",
      }
    }
  },
  "sameWithPrimaryGuest": "Same as Representative",
  "register": "Register"
};
