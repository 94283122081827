import transform from 'lodash/transform';
import set from 'lodash/set';
import isArray from 'lodash/fp/isArray';
import isPlainObject from 'lodash/fp/isPlainObject';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';

/**
 * Recursively camelize keys of a given object.
 */
export const camelCaseKeys = (obj) => {
  if (isArray(obj)) return obj.map(camelCaseKeys);
  if (isPlainObject(obj)) return transform(obj, (result, value, key) => set(result, camelCase(key), camelCaseKeys(value)));
  return obj;
};

/**
 * Recursively snakeCase keys of a given object.
 */
export const snakeCaseKeys = (obj) => {
  if (isArray(obj)) return obj.map(snakeCaseKeys);
  if (isPlainObject(obj)) return transform(obj, (result, value, key) => set(result, snakeCase(key), snakeCaseKeys(value)));
  return obj;
}
