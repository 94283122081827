import {
  Box,
  Stack,
  Divider,
  Link as MUILink,
} from '@mui/material';
import { Link } from 'react-router-dom';
import i18n from '@src/i18n';
import { useReservationContext } from '@src/providers/ReservationProvider';
import dayjs from 'dayjs';

const supportedLanguages = [
  { language: 'ja', label: '日本語' },
  { language: 'en', label: 'English' },
  { language: 'zh-CN', label: '简体中文' },
  { language: 'ko', label: '한국어' },
];

const LanguageSwitcher = (props) => {
  const { reservation, update, guestBooks } = useReservationContext();

  const switchLanguage = (event) => {
    const { language } = event.currentTarget.dataset;

    i18n.changeLanguage(language);
    dayjs.locale(language);

    if (guestBooks) {
      update({ language });
    }
  };

  return (
    <Box bgcolor="background.grey" py={0.8}>
      <Stack
        direction="row"
        spacing={2}
        sx={{ justifyContent: 'center' }}
      >
        {supportedLanguages.map(({ language, label }) => (
          <MUILink
            key={language}
            component={Link}
            to={`?${new URLSearchParams({ language })}`}
            replace
            data-language={language}
            onClick={switchLanguage}
            color={language == i18n.language ? '#ccc' : "primary.contrastText"}
            underline={language == i18n.language ? 'none' : undefined}
          >{label}</MUILink>
        ))}
      </Stack>
    </Box>
  );
};

export default LanguageSwitcher;
