import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import H3 from '@src/components/typography/H3';
import AddGuestInfoButton from '@src/components/buttons/AddGuestInfoButton';
import { Link } from 'react-router-dom';
import CameraSelfie from './CameraSelfie';
import { Stack } from '@mui/material';

const PrimaryGuest: React.FC = () => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.PrimaryGuest' });
  const { reservation } = useReservationContext();
  const { guestBook: { hashedId, primaryGuest, checkinStatus, japaneseNationalOrResident } = {}} = useGuestBookContext();

  const href = `/${reservation.hashedId}/${hashedId}/primary-guest`;

  const showSelfie = () => {
    if (checkinStatus == 'selfie-registration' || checkinStatus == 'submitted'){
      return japaneseNationalOrResident;
    }else{
      return false;
    }
  }

  return (
    <Stack spacing={1} sx={{ mb: 2 }}>
      <Typography
        variant='label3'
        sx={{
          minHeight: 32,
          background: '#f5f5f5',
          px: 1.5,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {t('title')}
      </Typography>
      {primaryGuest?.status == 'unready' ?
        <AddGuestInfoButton linkTo={href}>
          {t('register')}
        </AddGuestInfoButton>:
        <>
          <ListItem
            secondaryAction={
              <Button
                variant="bordered"
                size='small'
                component={Link}
                to={href}
              >
                {t('update')}
              </Button>
            }
          >
            <ListItemText>
              <Typography variant='label3'>{primaryGuest?.name}</Typography>
            </ListItemText>
          </ListItem>
          {/* {showSelfie() == true && <CameraSelfie />} */}
        </>
      }
    </Stack>
  );
};

export default PrimaryGuest;

