import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from '@mui/material';
import SubmitButton from '@src/components/buttons/SubmitButton';
import SearchIcon from '@mui/icons-material/Search';
import { useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inputRef } from '@src/util/form';
import { useAPI, useMutationAPI } from '@src/util/api';
import { useForm } from 'react-hook-form';

import { useQuery } from '@src/util/router';
import FormErrorMessage from '@src/components/ui/FormErrorMessage';

const Form: React.FC = (props) => {
  const { t } = useTranslation("common", { keyPrefix: "components.Root.Form" });
  const { id } = useQuery();
  const { register, setValue, setError, handleSubmit, formState: { isSubmitting, errors } } = useForm();
  const navigate = useNavigate();

  const { data } = useAPI(
    `/v1/checkin/properties/${id}`
  );

  const { trigger } = useMutationAPI('/v1/checkin/reservations');

  const onSubmit = (query) => {
    return trigger({ method: 'GET', query }).then(([reservation]) => {
      if (reservation) {
        navigate(`/${reservation.hashedId}`, { state: reservation });
      } else {
        setError("bookingNumber", { type: 'custom', message: t('notFound') });
      }
    });
  };

  useEffect(() => {
    setValue('propertyId', id);
  }, [id]);

  return (
    <Box component="form" onSubmit={handleSubmit(onSubmit)} {...props}>
      <Stack spacing={4}>

        {/* CHECK: Selectの挙動がわからん */}
        <TextField
          label={t('property')}
          variant="standard"
          select
          SelectProps={{
            native: true,
          }}
          InputLabelProps={{ shrink: true }}
          size="large"
          fullWidth
          {...inputRef(register('propertyId', { required: false }))}
        >
          {data?.length && <option value="" />}
          {[].concat(data || []).map(prop => (
            <option key={prop.id} value={prop.id}>{prop.localized('title')}</option>
          ))}
        </TextField>

        <Box>
          <FormControl fullWidth error={!!errors.bookingNumber}>
            <InputLabel htmlFor="booking-number">{t('bookingNumber')}</InputLabel>
            <OutlinedInput
              id="booking-number"
              {...inputRef(register('bookingNumber', { required: t('errorMessage.required') }))}
            />
            {errors.bookingNumber &&
              <FormErrorMessage message={errors.bookingNumber.message} />
            }
          </FormControl>
          <FormHelperText>
            {t('bookingNumberHelp')}
          </FormHelperText>
        </Box>
      </Stack>

      <Box sx={{
        maxWidth: 320,
        mx: 'auto',
        mt: 4,
        mb: 1,
      }}>
        <SubmitButton
          startIcon={<SearchIcon />}
          loading={isSubmitting}
          fullWidth
        >
          {t("submit")}
        </SubmitButton>
      </Box>
    </Box>
  );
};

export default Form;
