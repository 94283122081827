import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import logo from "@src/assets/logo.svg";
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const { t } = useTranslation("common", { keyPrefix: "components.LockScreen" });

  return (
    <Stack alignItems='center'>
      <Box
        src={logo}
        alt="Rakuten STAY GUEST BOOK"
        sx={{ height: 96 }}
        component="img"
        display="block"
        mx="auto"
      />
      <Typography variant='label2Bold' color='text.secondary'>
        {t('productName')}
      </Typography>
    </Stack>
  );
};

export default Header;

