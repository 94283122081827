import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { applicationData } from "@src/util/environment";

const { env: environment, sentry: dsn } = applicationData;

if (dsn) {
  Sentry.init({
    dsn,
    environment,
    integrations: [
      new BrowserTracing(),
    ],
    tracesSampleRate: 0.1,
  });
}
