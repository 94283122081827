import { ChangeEvent } from "react";
import {
  Typography,
  Container,
  FormControl,
  OutlinedInput,
  RadioGroup,
  InputLabel,
  FormControlLabel,
  Radio
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormContext, Controller } from 'react-hook-form';
import { emailRegex } from '@src/util/form';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import FormErrorMessage from "@src/components/ui/FormErrorMessage";

interface InputTypes {
  email: string,
  preference: {
    checkinNotification: string
  }
}

const RadioStyle = (checked: boolean) => {
  return {
    border: checked ? '1px solid #507763' : '1px solid #ccc',
    pr: 2,
    pl: 0.5,
    minHeight: 48,
    borderRadius: '6px',
    ml: 0,
    flex: 1,
    '&:hover': {
      borderColor: checked ? '#507763': '#555',
      '.MuiRadio-root': {
        color: '#555',
      },
    },
    background: checked ? '#F0F5F3' : 'transparent',
    '.MuiRadio-root': {
      color: '#aaa',
      '&.Mui-checked': {
        color: checked ? '#507763' : 'black',
      },
    }
  }
}

const EmailNotification = () => {
  const {
    control,
    formState: { errors },
    setValue,
    watch,
    clearErrors ,
  } = useFormContext<InputTypes>();
  const { reservation } = useReservationContext();
  const { currentGuest } = useGuestBookContext();
  const guest = currentGuest();
  const { t } = useTranslation('GuestForm');
  const [ checkinNotification ] = watch(['preference.checkinNotification'])

  return (
    <>
      <Container disableGutters sx={{ px: 3, pt: 3, pb: 4 }}>
        <Typography variant='label3Bold' component='div' sx={{ mb: 1 }}>
          {t('section3.title')}
        </Typography>
        <Typography paragraph variant="body1" sx={{ mb: 0.5 }}>
          {t('section3.description')}
        </Typography>
        <Typography paragraph variant='label5' color='text.secondary'>
          {t('section3.caption')}
        </Typography>

        <Controller
          control={control}
          name="preference.checkinNotification"
          rules={{ required: t('guest.checkinNotification.errors.required') }}
          render={({ field, fieldState }) => (
            <FormControl fullWidth error={fieldState.invalid}>
              <InputLabel>
                {t('guest.checkinNotification.label')}
              </InputLabel>
              <RadioGroup
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  setValue('preference.checkinNotification', event.target.value);
                  clearErrors('preference.checkinNotification');
                }}
                sx={{
                  justifyContent: 'space-between'
                }}
                row
              >
                <FormControlLabel
                  {...field}
                  value="disabled"
                  control={<Radio disableRipple />}
                  label={t('no')}
                  checked={checkinNotification === 'disabled'}
                  sx={RadioStyle(checkinNotification === 'disabled')}
                />
                <FormControlLabel
                  {...field}
                  value="enabled"
                  control={<Radio disableRipple />}
                  label={t('yes')}
                  checked={checkinNotification === 'enabled'}
                  sx={{
                    mr: 0,
                    ...RadioStyle(checkinNotification === 'enabled')
                  }}
                />
              </RadioGroup>
              {fieldState.invalid &&
                <FormErrorMessage message={errors?.preference?.checkinNotification?.message} />
              }
            </FormControl>
          )}
        />

        {checkinNotification === 'enabled' && (
          <Controller
            name="email"
            control={control}
            rules={{
              required: t('guest.email.errors.required'),
              pattern: {
                value: emailRegex,
                message: t('guest.email.errors.invalid')
              }
            }}
            render={({ field, fieldState }) => (
              <FormControl fullWidth error={fieldState.invalid}>
                <InputLabel sx={{ mt: 2 }} htmlFor='guest-email'>{t('guest.email.label')}</InputLabel>
                <OutlinedInput
                  {...field}
                  id='guest-email'
                  type="email"
                  placeholder={t('guest.email.placeholder')}
                />
                {fieldState.invalid && <FormErrorMessage message={errors?.email?.message} />}
              </FormControl>
            )}
          />
        )}
      </Container>
    </>
  );
};

export default EmailNotification;
