import { ReactNode } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import { IconButton, Stack } from '@mui/material';
import CustomIcon from '@src/components/ui/CustomIcon';

const NumberOfGuests = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.NumberOfGuests' });
  const { guestBook, updateGuestBook } = useGuestBookContext();
  const { register, watch, setValue, setFocus, handleSubmit } = useForm({ defaultValues: guestBook });

  const [numberOfGuests, editNumberOfGuests] = watch(['numberOfGuests', 'editNumberOfGuests']);

  const onSubmit = (form: { numberOfGuests: number }) => {
    const { numberOfGuests } = form;
    setValue('editNumberOfGuests', false);
    updateGuestBook({ numberOfGuests });
  };

  const showEditNumberOfGuest = () => {
    setValue('editNumberOfGuests', true);
  };

  const closeEditNumberOfGuest = () => {
    setValue('numberOfGuests', guestBook.numberOfGuests);
    setValue('editNumberOfGuests', false);
  };

  const up = () => {
    if(numberOfGuests === guestBook.maximumGuests){ return; }
    setValue('numberOfGuests', numberOfGuests + 1);
  };

  const down = () => {
    if(numberOfGuests === 1){ return; }
    setValue('numberOfGuests', numberOfGuests - 1);
  };

  if (!guestBook) { return; }

  return (<>
    {!editNumberOfGuests? (<>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          justifyContent: 'space-between',
          mb: 2
        }}
      >
        <Typography variant='label3'>{t('total', { count: numberOfGuests })}</Typography>
        <Button onClick={showEditNumberOfGuest} variant='bordered' size='small'>{t('edit')}</Button>
      </Stack>
      {children}
    </>) : (<>
      <Stack spacing={2}>
        <Typography variant='label3'>
          {t('description')}
        </Typography>

        <Stack direction='row' spacing={2} sx={{ justifyContent: 'center', alignItems: 'center'}}>
          <IconButton onClick={down} disabled={numberOfGuests == 1}>
            <CustomIcon
              variant='stepperMinus'
              width={48}
              color={numberOfGuests == 1 ? 'disabled' : 'green'}
            />
          </IconButton>
          <Typography {...{inert:""}} sx={{ fontSize: 48, lineHeight: 1, width: 48, textAlign: 'center'}} component='span'>
            { numberOfGuests }
          </Typography>
          <IconButton onClick={up} disabled={numberOfGuests == guestBook.maximumGuests}>
            <CustomIcon
              variant='stepperPlus'
              width={48}
              color={numberOfGuests == guestBook.maximumGuests ? 'disabled' : 'green'}
            />
          </IconButton>
        </Stack>

        <Stack direction='row' spacing={1}>
          <Button fullWidth variant='secondary' onClick={closeEditNumberOfGuest} >{t('cancel')}</Button>
          <Button fullWidth variant="primary" onClick={handleSubmit(onSubmit)} >{t('update')}</Button>
        </Stack>

        <Stack spacing={1}>
          <Stack direction='row' spacing={0.25}>
            <CustomIcon variant='warning' color='red' />
            <Typography color='rakuten.main' variant='label4Bold'>
              {t('warning.title')}
            </Typography>
          </Stack>

          <Typography color='rakuten.main' variant="label5" style={{ whiteSpace: "pre-line" }}>{t('warning.description')}</Typography>
        </Stack>
      </Stack>
    </>)}
  </>);
};

export default NumberOfGuests;

