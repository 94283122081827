export default {
  "onlineCheckIn": "ONLINE CHECK-IN",
  "guestBook": "투숙객 명부",
  "guestBookIndex": "숙박자 목록 ({{n}}번 방)",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "체크인 전에 모든 투숙객 정보 등록을 완료하십시오.",
      },
      "submitted": {
        "message": "체크인이 준비되었습니다.\n체크인 접수 시작 시간은 {{checkinTime}}부터입니다.",
      },
      "checked-in": {
        "message": "이 예약은 체크인이 완료되었습니다.\n\n객실 번호 또는 룸 키를 잊어버린 경우, 이 화면에 표시된 이차원 코드 또는 체크인 번호를 사용하여 그 재표시 작업을 체크인 단말기에서 수행하십시오.",
      },
      "checked-out": {
        "message": "Rakuten STAY에서 숙박해 주셔서 진심으로 감사합니다.\n\n다음 이용을 기대하고 있습니다.",
      },
    },
    "NumberOfGuests": {
      "total": "투숙객 총 인원: {{count}}명",
      "registered": "<0>등록된 인원: <1>{{count}}명</1></0>",
      "description": "투숙객 수를 등록하십시오.",
      "caption": "등록된 모든 투숙객에 대한 정보를 등록하십시오.",
      "edit": "편집",
      "cancel" : "취소",
      "update": "수정",
      "numberOfGuestsWithCount": "{{count}} 명",
      "warning": {
        "title": "주의해주세요",
        "description": "숙박 인원수의 증감에 따른 요금 변동은 없습니다.\n또한, 이미 주문하신 식사가 있는 경우 그 수량은 변경되지 않으니 양해 부탁드립니다.\n인원을 줄이는 경우, 이미 등록된 정보는 삭제됩니다."
      },
    },
    "PrimaryGuest": {
      "title": "주 투숙객",
      "register": "등록",
      "update": "편집",
    },
    "CheckinCode": {
      "description": "이 체크인 단말기 (SMART CHECK-IN) 에서 이 QR 코드 또는 체크인 번호를 사용하여 체크인을 진행해 주세요.",
      "label": "체크인 번호",
      "ciQrCode": "체크인 QR",
      "photo": {
        "instruction_1": "체크인 당일, 현장에서 신원 확인을 할 수 있습니다.",
        "instruction_2": "대표자의 얼굴 정보를 등록하여 온라인 체크인을 완료하세요.",
        "submitted": "얼굴 이미지 등록 완료",
      },
    },
    "AccompanyingGuests": {
      "title_zero": "동반 투숙객",
      "title": "동반 투숙객_{{count}}",
      "register": "등록",
      "update": "편집",
    },
    "MultipleRooms": {
      "label": "여러 개의 객실이 예약되었습니다.",
      "description": "각 객실마다 체크인이 필요하므로 각각의 객실에 대해 온라인 체크인을 해주세요.",
      "ongoing": "미완료",
      "complete": "완료",
      "selectedRoom": "선택된 객실",
      "roomIndex": "{{n}}번째 객실"
    }
  },
};
