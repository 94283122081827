import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import dayjs from 'dayjs';
import { Launch } from '@mui/icons-material';

const ListItem = ({ label, value }: { label: string, value: string }) => (
  <Stack
    direction='row'
    sx={{
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: 0.5,
      alignItems: 'center',
      borderBottom: '1px solid #ddd',
      minHeight: 48,
      px: 1,
      py: 1.5,
      justifyContent: 'space-between',
    }}
  >
    <Typography variant="label4" color="text.secondary">
      { label }
    </ Typography>
    <Typography variant="label4" sx={{ wordBreak: 'break-all' }}>
      { value }
    </ Typography>
  </Stack>
);

const ReservationSummary = ({ ...rest }) => {
  const { t } = useTranslation('common');
  const { reservation, mutate, error, isLoading } = useReservationContext();
  const keyPrefix = "components.ReservationSummary";

  if (isLoading) {
    return <CircularProgress />;
  }

  if (error) {
    console.error(error);
    return <Alert severity="warning">{t(`${keyPrefix}.failed`)}</Alert>;
  }

  if (!reservation) {
    return;
  }

  return (
    <Box {...rest}>
      <Typography variant="label2Bold" component="div" sx={{ mb: 2 }}>
        {t(`${keyPrefix}.title`)}
      </Typography>

      {reservation.name &&
        <ListItem label={t(`${keyPrefix}.name`)} value={reservation.name} />
      }
      <ListItem label={t(`${keyPrefix}.bookingNumber`)} value={reservation.bookingNumber} />
      <ListItem label={t(`${keyPrefix}.property`)} value={reservation.property.localized('title')} />
      <ListItem label={t(`${keyPrefix}.numberOfRooms`)} value={t('numWithUnit.room', { count: reservation.numberOfRooms})} />
      <ListItem label={t(`${keyPrefix}.numberOfGuests`)} value={t('numWithUnit.guest', { count: reservation.numberOfGuests})} />
      <ListItem label={t(`${keyPrefix}.checkin`)} value={dayjs(reservation.checkin).format('LL (dd)')} />
      <ListItem label={t(`${keyPrefix}.checkout`)} value={dayjs(reservation.checkout).format('LL (dd)')} />
    </Box>
  );
};

export default ReservationSummary;
