import React, { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Stack,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import SubmitButton from '@src/components/buttons/SubmitButton';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { inputRef } from '@src/util/form';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useForm } from 'react-hook-form';
import FormErrorMessage from '@src/components/ui/FormErrorMessage';

const Form = ({
  authCode,
  ...props
 } : {
  authCode: string,
 }) => {
  const { t, i18n } = useTranslation("common", { keyPrefix: "components.LockScreen.Form" });
  const { register, setError, handleSubmit, formState: { errors, isSubmitting } } = useForm();
  const { unlock } = useReservationContext();
  const navigate = useNavigate();

  const onSubmit = (query) => {
    const { language } = i18n;

    return unlock({ ...query, language }).then(({ guestBooks }) => {
      if (!guestBooks) {
        setError("code", { type: 'custom', message: t('errorMessage.invalid') });
      }
    });
  };

  const rules= {
    required: t('errorMessage.required')
  }

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      {...props}
    >
      {authCode ? (
        <input type="hidden" {...register('code', { value: authCode })} />
      ) : (
        <Box sx={{ mt: 4 }}>
          <FormControl fullWidth error={!!errors.code}>
            <InputLabel htmlFor="pass-code">{t('passCode')}</InputLabel>
            <OutlinedInput
              id="pass-code"
              {...inputRef(register('code', rules))}
            />
            {errors.code &&
              <FormErrorMessage message={errors.code.message} />
            }
          </FormControl>
          <FormHelperText>{t('helperText')}</FormHelperText>
        </Box>
      )}


      <Stack
        spacing={1}
        sx={{
          maxWidth: 320,
          mx: 'auto',
          mt: 4,
          mb: 1,
        }}
      >
        <SubmitButton
          loading={isSubmitting}
          fullWidth
        >
          {t(authCode ? "register" : "submit")}
        </SubmitButton>

        {authCode && (
          <Button
            variant='secondary'
            onClick={() => navigate(-1)}
          >
            {t('common:cancel')}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default Form;

