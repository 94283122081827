import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import AddGuestInfoButton from '@src/components/buttons/AddGuestInfoButton';
import { Link } from 'react-router-dom';
import { Stack } from '@mui/material';

const AccompanyingGuests: React.FC = () => {
  const { t } = useTranslation('Entrance', { keyPrefix: 'components.AccompanyingGuests' });
  const { reservation } = useReservationContext();
  const { guestBook: { companions, hashedId } = {}} = useGuestBookContext();

  return (
    <Stack spacing={2}>
      {companions.map((guest: { status: string, name: string }, index: number) => (
        <Stack spacing={1} sx={{ mb: 2 }} key={index}>
          <Typography
            variant='label3'
            sx={{
              minHeight: 32,
              background: '#f5f5f5',
              px: 1.5,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {t('title', { count: index + 1 })}
          </Typography>

          {!guest.status || (guest.status === 'unready') ?
            <AddGuestInfoButton
              linkTo={`/${reservation.hashedId}/${hashedId}/guests/${index + 1}`}
            >
              {t('register')}
            </AddGuestInfoButton> :
            <>
              <ListItem
                secondaryAction={
                  <Button
                    variant="bordered"
                    size='small'
                    component={Link}
                    to={`/${reservation.hashedId}/${hashedId}/guests/${index + 1}`}
                  >
                    {t('update')}
                  </Button>
                }
              >
                <ListItemText>
                  <Typography variant='label3'>{guest.name}</Typography>
                </ListItemText>
              </ListItem>
            </>
          }
        </Stack>
      ))}
    </Stack>
  );
};

export default AccompanyingGuests;

