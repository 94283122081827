import { Box, SxProps } from "@mui/material";
import WarningIconSvg from '@src/assets/icons/warning-filled.svg';
import StepperMinusIconSvg from '@src/assets/icons/stepper-minus.svg';
import StepperPlusIconSvg from '@src/assets/icons/stepper-plus.svg';
import DomesticIconSvg from '@src/assets/icons/domestic.svg';
import WorldIconSvg from '@src/assets/icons/world.svg';
import FaceMatch from '@src/assets/icons/face-match.svg';


const srcWithVariant: { [key: string]: string } = {
  warning: WarningIconSvg,
  stepperMinus: StepperMinusIconSvg,
  stepperPlus: StepperPlusIconSvg,
  domestic: DomesticIconSvg,
  world: WorldIconSvg,
  faceMatch: FaceMatch,
}

// https://codepen.io/sosuke/pen/Pjoqqp
const filterWithColor: { [key: string]: string } = {
  black: 'invert(0%) sepia(90%) saturate(7461%) hue-rotate(115deg) brightness(89%) contrast(110%)',
  green: 'invert(41%) sepia(42%) saturate(293%) hue-rotate(97deg) brightness(93%) contrast(83%)',
  red: 'invert(10%) sepia(68%) saturate(5927%) hue-rotate(358deg) brightness(106%) contrast(117%)',
  disabled: 'invert(74%) sepia(0%) saturate(0%) hue-rotate(229deg) brightness(94%) contrast(87%)',
  error: 'invert(9%) sepia(95%) saturate(5886%) hue-rotate(358deg) brightness(99%) contrast(108%)',
  white: 'invert(100%) sepia(0%) saturate(0%) hue-rotate(162deg) brightness(101%) contrast(102%)',
}

type Props = {
  variant: string,
  color?: string,
  width?: number | 'auto',
  height?: number | 'auto',
  sx?: SxProps,
}

const CustomIcon = ({ variant, color='black', width='auto', height='auto', sx }: Props) => {

  return (
    <Box
      component='img'
      src={srcWithVariant[variant]}
      alt=""
      width={width}
      height={height}
      sx={{
        filter: filterWithColor[color],
        ...sx
      }}
    />
  );
}

export default CustomIcon;
