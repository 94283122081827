  import React, { useState, useEffect } from "react";
  import { Box, Typography, Button, Stack } from '@mui/material';
  import { useTranslation } from 'react-i18next';
  import SubmitButton from '@src/components/buttons/SubmitButton';
  import { useGuestBookContext } from '@src/providers/GuestBookProvider';
  import { useReservationContext } from '@src/providers/ReservationProvider';
  import Guest from '@src/models/Guest';
  import WarningIcon from '@mui/icons-material/Warning';
  import ModeEditIcon from '@mui/icons-material/ModeEdit';
  import CameraAltIcon from '@mui/icons-material/CameraAlt';
  import { useMutationAPI } from '@src/util/api';
  import LoadingBackdrop from '@src/components/ui/LoadingBackdrop';
  import PassportForm from "./PassportForm";
  import MessageBar from "../../components/ui/MessageBar";
  import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
  import 'react-html5-camera-photo/build/css/index.css';
  import countries from "../../countries";
  import PassportOverlaySvg from "@src/assets/passport-overlay.svg";

  const CameraPassport: React.FC = ({ setIsEditing, dialogClose, setImage }) => {
    const { t } = useTranslation('GuestForm', { keyPrefix: 'components.CameraPassport' });
    const { guestBook, currentGuest, updateCurentGuest } = useGuestBookContext();
    const { reload } = useReservationContext();
    const [showCapture, setShowCapture] = useState(false);
    const [passport, setPassport] = useState(null)
    const [showAddForm, setShowAddForm] = useState(false)
    const [captureImage, setCaptureImage] = useState('');
    const [error, setError] = useState(null);

    const { trigger, isMutating } = useMutationAPI(
      `/v1/checkin/guest_books/${guestBook.hashedId}/guests/${currentGuest().hashedId}/photos`,
      { model: Guest }
    );

    function moveToCameraScreen() {
      setImage(null)
      setCaptureImage(null)
      setPassport(null)
      setShowCapture(false)
      setShowAddForm(false)
    }

    function capturePhoto() {
      const defaultButton = document.getElementById('outer-circle');
      if (defaultButton) { defaultButton.click(); }
    }

    function useThisPhoto() {
      const body = { base64: captureImage, type: 'passport' }
      trigger({ method: 'POST', body }).then((response) => {
        setPassport(response)

        if (validPassport(response)) {
          setError(null);
        } else {
          let msg = response?.reason || ''
          if (response?.errorMessages) {
            msg = response.errorMessages.map(item =>  JSON.parse(item).message).join(', ');
          }
          setError(`${t('error.failedScan')} ${msg}`)
        }
      }).catch((error) => {
        setError(`${t('error.failedScan')} ${error.message}`);
      })
    }

    function moveToCompletePage() {
      reload();
      dialogClose() // close the dialog
      setIsEditing(true) // move to next input adrress & phoneNumber
    }

    function displayAddForm() {
      setShowAddForm(true)
      setPassport(null)
    }

    function validPassport(response) {
      if (!response) { return false; }

      const { name, documentNumber, responseMessage } = response

      return (responseMessage === 'success' && documentNumber && name)
    }

    if (isMutating) {
      return <LoadingBackdrop />;
    }

    function handleTakePhoto(dataUri) {
      setImage(dataUri);
      setCaptureImage(dataUri);
      setShowCapture(true)
      setShowAddForm(false)
    }

    return (
      <>
        {!showCapture &&
          <Box>
            <Stack
              sx={{
                p: 3,
                maxWidth: 648,
                margin: '24px auto',
                position: 'relative',
                background: 'white',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                borderRadius: 3,
                '.react-html5-camera-photo': {
                  height: '100vw',
                  maxHeight: 600,
                  '.display-error': {
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    position: 'absolute',
                    fontSize: '6px',
                    padding: '2px',
                    zIndex: 1,
                    width: '100%',
                  }
                },
                'video': {
                  objectFit: 'cover',
                  background: '#fff',
                  width: '100%',
                  height: '100%',
                  '@media (max-width: 600px)': {
                    height: '100vw',
                  }
                },
                '@media (max-width: 648px)': {
                  p: 0,
                  pb: 2,
                  borderRadius: 0,
                  margin: 0,
                  borderBottom: '1px solid #ddd',
                  boxShadow: 'none',
                }
              }}
            >
              <Camera
                id="camera"
                onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                isFullscreen={false}
                idealResolution = {{width: 900, height: 900}}
                imageType={IMAGE_TYPES.JPG}
                idealFacingMode={FACING_MODES.ENVIRONMENT}
                isImageMirror={false}
                isSilentMode={true}
                isMaxResolution={false}
                onCameraError={(error) => {
                  console.log(error);
                }}
              />
              <Stack spacing={2} sx={{ pt: 2, '@media (max-width: 648px)': { p: 2, pb: 1} }}>
                <Typography variant="body1" component="div">{t('instruction')}</Typography>
                <Stack spacing={2} direction="row" sx={{ pt: 2 }}>
                  <Button fullWidth variant="secondary" onClick={dialogClose}>{t('cancel')}</Button>
                  {captureImage
                    ? <SubmitButton fullWidth onClick={useThisPhoto}>{ t('scan') }</SubmitButton>
                    : <SubmitButton fullWidth startIcon={<CameraAltIcon />} onClick={capturePhoto}>{t('capture')}</SubmitButton>
                  }
                </Stack>
              </Stack>
              <Stack
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  left: 0,
                  zIndex: 0,
                }}
              >
                <Stack
                  sx={{
                    maxHeight: 600,
                    height: 600,
                    mx: 3,
                    mt: 3,
                    '@media (max-width: 648px)': {
                      m: 0,
                      maxHeight: 'none',
                      height: '100vw',
                    },
                  }}
                >
                  <Box sx={{ background: 'rgba(0, 0, 0, 0.6)', height: '100%' }} />
                  <Stack sx={{ flexDirection: 'row' }}>
                    <Box sx={{ flex: 1, background: 'rgba(0, 0, 0, 0.6)', height: '100%', mr: -2 }}/>
                    <Box component='img' src={PassportOverlaySvg} sx={{ width: '100vw', maxWidth: 540, px: 2 }}/>
                    <Box sx={{ flex: 1, background: 'rgba(0, 0, 0, 0.6)', height: '100%', ml: -2 }}/>
                  </Stack>
                  <Box sx={{ background: 'rgba(0, 0, 0, 0.6)', height: '100%' }}/>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        }

        {showCapture && <>
          <Stack
            spacing={2}
            sx={{
              p:3,
              alignItems: 'center',
              maxWidth: 648,
              margin: '24px auto',
              position: 'relative',
              background: 'white',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              borderRadius: 3,
              '@media (max-width: 648px)': {
                p: 0,
                borderRadius: 0,
                m: 0
              },
            }}
          >
            <Box component="img"
              alt="Passport"
              src={captureImage}
              sx={{ width: "100%" }}
            />
            {!passport &&
              <Button
                variant="bordered"
                size="small"
                startIcon={<CameraAltIcon />}
                onClick={moveToCameraScreen}
              >
                {t('captureAgain')}
              </Button>
            }

            {!passport && !showAddForm &&
              <>
                <Typography variant="body1">{t('captureNotePassport')}</Typography>
                <Stack spacing={1} direction='row' sx={{ width: '100%' }}>
                  <Button fullWidth variant="secondary" onClick={dialogClose}>{t('cancel')}</Button>
                  {captureImage ?
                    <SubmitButton fullWidth onClick={useThisPhoto}>{ t('scan') }</SubmitButton> :
                    <SubmitButton
                      fullWidth
                      startIcon={<CameraAltIcon />}
                      onClick={capturePhoto}
                    >
                      {t('capture')}
                    </SubmitButton>
                  }
                </Stack>
              </>
            }

            {(passport && validPassport(passport)) &&
              <Stack spacing={2} sx={{ '@media (max-width: 648px)': { p:3, pt: 1 } }}>
                <Stack spacing={0.5}>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>PASSPORT No</Typography>
                    <Typography variant="label3">{ passport.documentNumber || "--------------" }</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>NAME</Typography>
                    <Typography variant="label3">{ passport.name || "---- ----" }</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>NATIONALITY</Typography>
                    <Typography variant="label3">{ countries.find(c => c.code === passport.countryCode)?.label || "-----" }</Typography>
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Typography variant="label3" color="text.secondary" sx={{ width: '10em' }}>DATE OF EXPIRY</Typography>
                    <Typography variant="label3">{ passport.expiryDate || "-- --- ----" }</Typography>
                  </Stack>
                </Stack>

                <Typography variant="body1">{t('scanSuccess')}</Typography>

                <Stack spacing={1}>
                  <SubmitButton onClick={moveToCompletePage}>{t('registerContent')}</SubmitButton>
                  <Button variant="secondary" onClick={dialogClose}>{t('cancel')}</Button>
                </Stack>
              </Stack>
            }

            {(passport && !validPassport(passport)) &&
              <Stack spacing={2}
                sx={{
                  '@media (max-width: 648px)': {
                    p: 3,
                    pt: 1
                  },
                }}
              >
                <Stack spacing={1}>
                  <Typography variant="label3Bold" sx={{ color: 'error.main', display: 'flex', alignItems: 'center' }}>
                    <WarningIcon sx={{ verticalAlign: 'middle', mr: 1 }} />
                    { t('scanFailed') }
                  </Typography>
                  <Typography variant="body1">{t('scanFailedInstruction')}</Typography>
                </Stack>
                <Stack spacing={1} sx={{ pb: 2 }}>
                  <Button variant="bordered" startIcon={<CameraAltIcon />} onClick={moveToCameraScreen} sx={{ color: "secondary.main" }}>
                    { t('takeAnotherPhoto') }
                  </Button>
                  <Button variant="bordered" startIcon={<ModeEditIcon />} onClick={displayAddForm} sx={{ color: "secondary.main" }}>
                    { t('addManually') }
                  </Button>
                </Stack>
                <Button variant="secondary" onClick={dialogClose}>{t('cancel')}</Button>
              </Stack>
            }

            {showAddForm &&
              <Stack maxWidth="sm" sx={{px: 1, pb: 1}}>
                <PassportForm dialogClose={dialogClose} setIsEditing={setIsEditing}  />
                <Button variant="secondary" onClick={dialogClose}>{t('cancel')}</Button>
              </Stack>
            }
          </Stack>
        </>}
        { error && <MessageBar message={error} severity='error' />}
      </>
    );
  };

  export default CameraPassport;
