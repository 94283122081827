import { useState, useEffect, ChangeEvent } from 'react';
import {
  Checkbox,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Typography,
  Box, Stack, Button,
  InputLabel
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useReservationContext } from '@src/providers/ReservationProvider';
import { useGuestBookContext } from '@src/providers/GuestBookProvider';
import { Controller, useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import Header from '@src/components/ui/Header';
import SubmitButton from '@src/components/buttons/SubmitButton';
//import AnnotationBanner from '@src/components/ui/AnnotationBanner';
import { ScrollToTop } from '@src/util/router';
import { useParams, useNavigate, Link as RouterLink } from 'react-router-dom';
import PhoneNumberInput from '@src/components/ui/PhoneNumberInput';
import pick from 'lodash/pick';
import './i18n';
import EmailNotification from './EmailNotification';
import PassportEdit from './PassportEdit';
import PassportVerify from './PassportVerify';
import GuestTypeSelectButton from '@src/components/buttons/GuestTypeSelectButton';
import CustomIcon from '@src/components/ui/CustomIcon';
import FormErrorMessage from '@src/components/ui/FormErrorMessage';

const GuestForm = ({
  primary
} : {
  primary?: boolean
}) => {
  const { t } = useTranslation('GuestForm');
  const { reservation, guestBooks } = useReservationContext();
  const { guestBook, currentGuest, updateGuestBook } = useGuestBookContext();
  const { primaryGuest } = guestBook;
  const { guestIndex } = useParams();
  const [phoneNumberValid, setPhoneNumberValid] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [image, setImage] = useState('');
  const [isCheckedSameAddress, setIsCheckedSameAddress] = useState(false);
  const [isCheckedSamePhoneNumber, setIsCheckedSamePhoneNumber] = useState(false);
  const guestBookIndex = guestBooks.findIndex((g) => g.hashedId == guestBook.hashedId );
  const guest = primary ? primaryGuest : guestBook.guests[Number(guestIndex)];
  const formDefaultValues = {
    ...guest,
    // To prevent null from being set to the value, assign an empty string instead.
    name: guest.name || '',
    email: guest.email || '',
    address: guest.address || '',
  }
  const methods = useForm({ defaultValues: {...formDefaultValues} });
  const {
    setValue,
    setFocus,
    clearErrors,
    formState: { isSubmitting, errors },
    handleSubmit,
    control,
    watch,
  } = methods;
  const [ready, setReady] = useState(false);
  const navigate = useNavigate();
  const [
    countryCode,
    address,
    phoneNumber,
    phoneNumberCountryCode,
    japaneseNationalOrResident
  ] = watch([
    'countryCode',
    'address',
    'phoneNumber',
    'phoneNumberCountryCode',
    'japaneseNationalOrResident'
  ]);

  useEffect(() => {
    if (guest.status === 'ready') { setReady(true) }
    if (!guest.countryCode) { setValue('countryCode', guest.countryCode); }
    if (!guest.japaneseNationalOrResident) { setValue('japaneseNationalOrResident', guest.japaneseNationalOrResident) }

    if (guest.role === 'primary') {
      if (!guest.address) { setValue('address', primaryGuest.address || ''); }
      if (!guest.phoneNumber) { setValue('phoneNumber', primaryGuest.phoneNumber); }
      if (!guest.phoneNumberCountryCode) { setValue('phoneNumberCountryCode', primaryGuest.phoneNumberCountryCode); }
    } else {
      if (address === primaryGuest.address) {
        setIsCheckedSameAddress(true)
      }
      if (phoneNumber === primaryGuest.phoneNumber && phoneNumberCountryCode === primaryGuest.phoneNumberCountryCode) {
        setIsCheckedSamePhoneNumber(true);
      }
    }

    if (guest.passportStatus === 'registered') {
      setIsEditing(true);
    }
  }, []);

  const attributes = (obj: object) => {
    return pick(
      obj,
      'hashedId',
      'countryCode',
      'name',
      'address',
      'phoneNumber',
      'phoneNumberCountryCode',
      'preference',
      'email',
      'japaneseNationalOrResident',
    )
  }

  const onSubmit: SubmitHandler<any> = (data) => {
    const guests = [attributes(data)];

    guestBook.guests.filter((g) => g.hashedId != data.hashedId).map((g) => guests.push(attributes(g)));

    return (
      updateGuestBook({
        numberOfGuests: guestBook.numberOfGuests,
        guests
      }).then(() => {
        navigate(`/${reservation.hashedId}?index=${guestBookIndex}`);
      }).catch((e: any) => {
        console.log(e);
      })
    );
  };

  // CHECK: 代表者の情報が更新されたらどうなる？
  const handleAddressCopy = (event: ChangeEvent<HTMLInputElement>) => {
    setIsCheckedSameAddress(event.target.checked);
    setValue('address', event.target.checked ? primaryGuest.address : '');
  };

  // CHECK: 代表者の情報が更新されたらどうなる？
  const handlePhoneNumberCopy = (event: ChangeEvent<HTMLInputElement>) => {
    clearErrors('phoneNumber');
    setPhoneNumberValid(true);
    setIsCheckedSamePhoneNumber(event.target.checked);
    setValue('phoneNumber', event.target.checked ? primaryGuest.phoneNumber : '');
    if (event.target.checked) {
      setValue('phoneNumberCountryCode', primaryGuest.phoneNumberCountryCode);
    }
  };

  const handlePhoneNumberCountryChange = (code: string) => {
    if (code === phoneNumberCountryCode) {
      return;
    }

    setValue('phoneNumberCountryCode', code.toLowerCase());

    setValue('phoneNumber', '');
    setFocus('phoneNumber');

    if (countryCode === 'NULL') {
      setValue('countryCode', code.toLowerCase());
    }
  };

  const handleJpNationaoOrResidentBtnClick = () => {
    if (countryCode == null) {
      setValue('countryCode', 'jp')
    }
    setValue('japaneseNationalOrResident', true)
  }

  const handleForeginerBtnClick = () => {
    setValue('japaneseNationalOrResident', false)

    // init companion
    // if (currentGuest().role == undefined) {
    //   updateGuestBook({
    //     guests: [
    //       { hashed_id: primaryGuest.hashedId },
    //       { countryCode: 'non_jp' }
    //     ]
    //   });
    // }
  };

  return (<>
    <ScrollToTop />

    <Header color="dark" sx={{ pl: 1 }}>
      <Typography variant='label3'>
        {t(primary ? 'title.primary' : 'title.companion', { count: Number(guestIndex) })}
      </Typography>
    </Header>

    {(japaneseNationalOrResident == null) &&
      <Container
        maxWidth="sm"
        disableGutters
        sx={{ bgcolor: "background.paper" }}
      >
        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="label3Bold" gutterBottom>
            {t('guest_type_menu.caption')}
          </Typography>
          <GuestTypeSelectButton
            onClick={handleJpNationaoOrResidentBtnClick}
            iconVariant='domestic'
            label={ t('guest_type_menu.japanese_title') }
            description={ t('guest_type_menu.japanese_caption') }
          />
          <GuestTypeSelectButton
            onClick={handleForeginerBtnClick}
            iconVariant='world'
            label={ t('guest_type_menu.foreigner_title') }
            description={ t('guest_type_menu.foreigner_caption') }
          />
        </Stack>

        <Divider />

        <Box sx={{ p: 3 }}>
          <Button
            fullWidth
            variant='secondary'
            component={RouterLink}
            to={`/${reservation.hashedId}?index=${guestBookIndex}`}
          >
            { t('components.CameraPassport.cancel') }
          </Button>
        </Box>
      </Container>
    }

    <Divider />

    {japaneseNationalOrResident && (
      <FormProvider {...methods}>
        <Container
          maxWidth="sm"
          disableGutters
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          sx={{ bgcolor: "background.paper" }}
        >
          <Stack direction="row" sx={{ justifyContent: 'space-between', px: 3, py: 1.5 }}>
            <Stack spacing={1} alignItems="center" direction="row">
              <CustomIcon variant='domestic' width={32} />
              <Typography variant="label4Bold">
                { t('guest_type_menu.japanese_title') }
              </Typography>
            </Stack>
            <Button
              variant="dangerBordered"
              size='small'
              onClick={() => {
                setValue('countryCode', 'NULL');
                setValue('japaneseNationalOrResident', null);
              }}
            >
              { t('components.CameraPassport.update') }
            </Button>
          </Stack>

          <Divider />

          <Container sx={{ px: 3, pt: 3, pb: 4 }}>
            <Typography variant='label3Bold' component='div' sx={{ mb: 3 }}>
              {t(`section2.title.${primary ? 'primary' : 'companion'}`, { count: Number(guestIndex) })}
            </Typography>

            <Stack spacing={4}>
              <Controller
                name="name"
                control={control}
                rules={{ required: t('guest.name.errors.required') }}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel htmlFor="guest-name">{t('guest.name.label')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="guest-name"
                      placeholder={t('guest.name.placeholder')}
                      inputProps={{ maxLength: 80 }}
                    />
                    {fieldState.invalid && <FormErrorMessage message={errors?.name?.message} />}
                  </FormControl>
                )}
              />

              <Controller
                name="address"
                control={control}
                rules={{ required: t('guest.address.errors.required') }}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel htmlFor="guest-address">{t('guest.address.label')}</InputLabel>
                    <OutlinedInput
                      {...field}
                      id="guest-address"
                      disabled={isCheckedSameAddress}
                      placeholder={t('guest.address.placeholder')}
                      sx={{
                        overflow: 'hidden',
                        '.Mui-disabled': {
                          color: '#333',
                          WebkitTextFillColor: '#333',
                          background: '#ddd',
                        }
                      }}
                    />
                    {!primary && (
                      <FormControlLabel
                        disabled={!primaryGuest.address}
                        componentsProps={{ typography: { variant: 'label3' }}}
                        control={
                          <Checkbox
                            onChange={handleAddressCopy}
                            checked={isCheckedSameAddress}
                          />
                        }
                        label={t('sameWithPrimaryGuest')}
                      />
                    )}
                    {fieldState.invalid && <FormErrorMessage message={errors?.address?.message} />}
                  </FormControl>
                )}
              />

              <Controller
                name="phoneNumber"
                control={control}
                rules={{
                  required: t('guest.phoneNumber.errors.required'),
                  validate: {
                    invalid: () => phoneNumberValid || t('guest.phoneNumber.errors.invalid')
                  }
                }}
                render={({ field, fieldState }) => (
                  <FormControl fullWidth error={fieldState.invalid}>
                    <InputLabel htmlFor="guest-phone-number">{t('guest.phoneNumber.label')}</InputLabel>
                    <PhoneNumberInput
                      {...field}
                      id="guest-phone-number"
                      disabled={isCheckedSamePhoneNumber}
                      initialValue={guest.phoneNumber || ''}
                      onChangeNumber={(value: string) => {
                        setValue('phoneNumber', value);
                        console.log(phoneNumber)
                      }}
                      onChangeCountry={handlePhoneNumberCountryChange}
                      onChangeValidity={(valid) => setPhoneNumberValid(valid)}
                      initOptions={{
                        initialCountry: guest.initialPhoneNumberCountryCode || t('guest.phoneNumber.defaultCountry'),
                        utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@19.1.0/build/js/utils.js",
                        // intl-tel-input version up needed.
                        // i18n: ja
                        // countryOrder: [ 'jp', 'cn', 'tw', 'kr']
                      }}
                      sx={{
                        borderRadius: '6px',
                        'input[type="tel"]': {
                          paddingLeft: '52px',
                        },
                        '.Mui-disabled': {
                          color: '#333',
                          WebkitTextFillColor: '#333',
                          background: '#ddd',
                          borderRadius: '6px',
                          'input': {
                            paddingLeft: '52px',
                          },
                        }
                      }}
                    />
                    {!primary && (
                      <FormControlLabel
                        disabled={!primaryGuest.phoneNumber}
                        componentsProps={{ typography: { variant: 'label3' }}}
                        control={
                          <Checkbox
                            onChange={handlePhoneNumberCopy}
                            checked={isCheckedSamePhoneNumber}
                          />
                        }
                        label={t('sameWithPrimaryGuest')}
                      />
                    )}
                    {fieldState.invalid && <FormErrorMessage message={errors?.phoneNumber?.message} />}
                  </FormControl>
                )}
              />
            </Stack>
          </Container>

          {primary && (
            <>
              <Divider />
              <EmailNotification />
            </>
          )}

          <Divider />

          <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ p: 3 }}>
            <Button
              fullWidth
              variant="secondary"
              component={RouterLink}
              to={`/${reservation.hashedId}?index=${guestBookIndex}`}
            >
              {t('back')}
            </Button>
            <SubmitButton fullWidth loading={isSubmitting}>{t('register')}</SubmitButton>
          </Stack>

        </Container>
      </FormProvider>
    )}

    {(japaneseNationalOrResident != null && !japaneseNationalOrResident) && (
      <Container maxWidth="sm" disableGutters sx={{ bgcolor: "background.paper"}}>
        <Stack direction="row" sx={{ justifyContent: 'space-between', px: 3, py: 1.5 }}>
          <Stack spacing={1} alignItems="center" direction="row">
            <CustomIcon variant='world' width={32} />
            <Typography variant="label4Bold">
              { t('guest_type_menu.foreigner_title') }
            </Typography>
          </Stack>
          <Button
            variant="dangerBordered"
            size='small'
            onClick={() => setValue('japaneseNationalOrResident', null)}
          >
            { t('components.CameraPassport.update') }
          </Button>
        </Stack>

        <Divider />

        {isEditing
          ? <PassportEdit
              setIsEditing={setIsEditing}
              image={image}
              setImage={setImage}
            />
          : <PassportVerify
              setValue={setValue}
              ready={ready}
              setIsEditing={setIsEditing}
              setImage={setImage}
            />
        }

        {/* <Stack direction="row" spacing={1} justifyContent="space-between" sx={{ p: 3 }}>
          <Button
            fullWidth
            variant="secondary"
            component={RouterLink}
            to={`/${reservation.hashedId}`}
          >
            {t('back')}
          </Button>
          <SubmitButton fullWidth loading={isSubmitting}>{t('register')}</SubmitButton>
        </Stack> */}

        <Divider />

      </Container>
    )}
  </>);
};

export default GuestForm;
