export default {
  "onlineCheckIn": "ONLINE CHECK-IN",
  "guestBook": "宾客登记簿",
  "guestBookIndex": "住宿者名单（第{{n}}房）",
  "components": {
    "Info": {
      "pre-entry": {
        "message": "请在办理入住手续前完成所有客人信息的登记。",
      },
      "submitted": {
        "message": "办理入住手续已准备就绪。\n办理入住手续接待开始时间为{{checkinTime}}。",
      },
      "checked-in": {
        "message": "此预订已办理入住手续。\n\n如果您忘记了房间号码或房卡，请使用此屏幕上显示的二维码或入住号码，在办理入住终端上执行它们的重新显示操作。",
      },
      "checked-out": {
        "message": "感谢您入住Rakuten STAY。\n\n期待再次为您服务。",
      },
    },
    "NumberOfGuests": {
      "total": "宾客总人数：{{count}}人",
      "registered": "<0>已登记：<1>{{count}}人</1></0>",
      "description": "请登记宾客人数。",
      "caption": "登记所有已登记宾客的信息。",
      "edit": "编辑",
      "cancel" : "取消",
      "update": "更新",
      "numberOfGuestsWithCount": "{{count}} 位住客",
      "warning": {
        "title": "请注意",
        "description": "因入住人数增减不会产生费用变化。\n此外，请注意，已预订的餐食数量不会更改。\n减少人数时，已注册的信息将被删除。"
      },
    },
    "PrimaryGuest": {
      "title": "主要宾客",
      "register": "登记",
      "update": "编辑",
    },
    "CheckinCode": {
      "description": "请在此登记终端（SMART CHECK-IN）上使用此二维码或登记号进行登记手续。",
      "label": "登记号",
      "ciQrCode": "报到 QR",
      "photo": {
        "instruction_1": "在入住当天，可以在现场进行身份验证",
        "instruction_2": "注册代表人的面部信息以完成在线入住",
        "submitted": "面部圖像已註冊",
      },
    },
    "AccompanyingGuests": {
      "title_zero": "陪同宾客",
      "title": "陪同宾客_{{count}}",
      "register": "登记",
      "update": "编辑",
    },
    "MultipleRooms": {
      "label": "已预订多个房间。",
      "description": "请分别为每个房间在线办理入住手续，因为每个房间都需要办理入住。",
      "ongoing": "未完成",
      "complete": "完成",
      "selectedRoom": "选定的房间",
      "roomIndex": "第{{n}}个房间"
    }
  },
};
